
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { KoruSortableBrowserModule } from "../objects/KoruSortableBrowserModule";
import { koruStoreTypes } from "@/core/modules/store/types";
import { RowCommandInterface } from "@/core/modules/layout/interfaces/RowCommand.interface";
import { useKoruLocale } from "@/core/modules/locale";
import { useKoruStore } from "@/core/modules/store";

import { getObjectValueWithField, tryOrError, tryOrToast } from "@/core/modules/helpers";
import { userCanRead, userCanUpdate, userCanDelete, userCanCreate } from "@/core/modules/user/helpers";

export default defineComponent({
  name: "KoruSortableBrowser",
  props: {
    module: { type: KoruSortableBrowserModule, default: undefined },
  },
  emits: ["update:module"],
  setup(props, { emit }) {
    const { t } = useKoruLocale();
    const koruStore = useKoruStore();

    const moduleRef = computed({
      get: () => props.module as KoruSortableBrowserModule<unknown>,
      set: (val) => emit("update:module", val),
    });

    const route = useRoute();
    const requiredModule: string =
      moduleRef.value.explicitRequiredModule !== undefined ? moduleRef.value.explicitRequiredModule : (route.meta.requiredModule as string);

    const handleLoad = async () => {
      await tryOrError(
        async () => {
          await moduleRef.value.onMountedAction();
          await moduleRef.value.onLoadAction();
        },
        () => {
          if (moduleRef.value.loadingStop) koruStore.commit(koruStoreTypes.mutations.loadingStop);
        }
      );
    };

    const onReorder = async (event: Record<string, unknown>) => {
      moduleRef.value.documents.value = event.value as unknown[];
      await moduleRef.value.onReorderAction(event.value as unknown[]);
    };

    const deleteAction = async (document: Record<string, unknown>) => {
      await tryOrToast(
        async () => {
          koruStore.commit(koruStoreTypes.mutations.loadingStart);
          await moduleRef.value.onDeleteAction(document);

          const deleteIndex = moduleRef.value.documents.value.indexOf(document, 0);
          if (deleteIndex > -1) {
            moduleRef.value.documents.value.splice(deleteIndex, 1);
          }
        },
        "delete",
        t,
        () => koruStore.commit(koruStoreTypes.mutations.loadingStop)
      );
    };

    const createAction = () => {
      if (moduleRef.value.createPath !== undefined) {
        koruStore.dispatch(koruStoreTypes.actions.navigateTo, moduleRef.value.createPath);
      } else {
        koruStore.dispatch(koruStoreTypes.actions.navigateTo, `${moduleRef.value.path}/new/edit`);
      }
    };

    const showDeleteItemDialog = ref(false);
    const deleteItem = ref({});

    const deleteItemAction = () => {
      showDeleteItemDialog.value = false;
      deleteAction(deleteItem.value);
    };

    const getRowCommands = (document: FirestoreDocument) => {
      const renderRowCommands: RowCommandInterface[] = [];

      if (moduleRef.value.canView && (requiredModule == undefined || userCanRead(requiredModule, document))) {
        renderRowCommands.push({
          label: t("buttons.view"),
          icon: "pi pi-eye",
          command: (object: Record<string, unknown>) => {
            koruStore.dispatch(koruStoreTypes.actions.navigateTo, `${moduleRef.value.path}/${(object.reference as Record<string, unknown>).id}`);
          },
        } as RowCommandInterface);
      }

      if (moduleRef.value.canEdit && (requiredModule == undefined || userCanUpdate(requiredModule, document))) {
        renderRowCommands.push({
          label: t("buttons.edit"),
          icon: "pi pi-pencil",
          command: (object: Record<string, unknown>) => {
            koruStore.dispatch(koruStoreTypes.actions.navigateTo, `${moduleRef.value.path}/${(object.reference as Record<string, unknown>).id}/edit`);
          },
        } as RowCommandInterface);
      }

      if (moduleRef.value.canDelete && (requiredModule == undefined || userCanDelete(requiredModule, document))) {
        renderRowCommands.push({
          label: t("buttons.delete"),
          icon: "pi pi-trash",
          command: (object: Record<string, unknown>) => {
            deleteItem.value = object.reference as Record<string, unknown>;
            showDeleteItemDialog.value = true;
          },
        } as RowCommandInterface);
      }

      if (moduleRef.value.rowCommands !== undefined && moduleRef.value.rowCommands.length > 0) {
        const rowCommandsLength = renderRowCommands.length;
        for (const rowCommand of moduleRef.value.rowCommands) {
          if (rowCommand.condition(document) == true) {
            if (renderRowCommands.length == rowCommandsLength && rowCommandsLength > 0) {
              renderRowCommands.push({ label: "separator" } as RowCommandInterface);
            }
            renderRowCommands.push(rowCommand);
          }
        }
      }

      if (renderRowCommands.length === 0) {
        renderRowCommands.push({
          label: t("buttons.noAction"),
          icon: "pi pi-times",
          condition: () => true,
          command: () => {
            return;
          },
        } as RowCommandInterface);
      }

      return renderRowCommands;
    };

    onMounted(() => {
      handleLoad();
    });

    return {
      createAction,
      deleteItemAction,
      deleteItem,
      getObjectValueWithField,
      getRowCommands,
      moduleRef,
      onReorder,
      requiredModule,
      showDeleteItemDialog,
      t,
      userCanCreate,
      userCanUpdate,
    };
  },
});
