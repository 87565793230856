
import { computed, ComputedRef, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { TreeNode } from "primevue/tree";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { koruEventBus } from "@/core/modules/event-bus";
import { koruStoreTypes } from "@/core/modules/store/types";
import { KoruTreeModule } from "../objects/KoruTreeModule";
import { RowCommandInterface } from "@/core/modules/layout/interfaces/RowCommand.interface";
import { useKoruLocale } from "@/core/modules/locale";
import { useKoruStore } from "@/core/modules/store";

import { getObjectValueWithField, tryOrError, tryOrToast } from "@/core/modules/helpers";
import { userCanUpdate, userCanDelete, userCanCreate } from "@/core/modules/user/helpers";

export default defineComponent({
  name: "KoruTree",
  props: {
    additionalClass: { type: String, default: "" },
    module: { type: KoruTreeModule, default: undefined },
  },
  emits: ["update:module"],
  setup(props, { emit }) {
    const { t } = useKoruLocale();
    const koruStore = useKoruStore();

    const moduleRef = computed({
      get: () => props.module as KoruTreeModule<unknown>,
      set: (val) => emit("update:module", val),
    });

    const route = useRoute();
    const requiredModule: string =
      moduleRef.value.explicitRequiredModule !== undefined ? moduleRef.value.explicitRequiredModule : (route.meta.requiredModule as string);

    const nodes: ComputedRef<TreeNode[]> = computed(() => moduleRef.value.computeNodes());

    const handleLoad = async () => {
      await tryOrError(
        async () => {
          await moduleRef.value.onMountedAction();
          await moduleRef.value.onLoadAction();
        },
        () => {
          if (moduleRef.value.loadingStop) koruStore.commit(koruStoreTypes.mutations.loadingStop);
        }
      );
    };

    const createAction = async (parent: unknown | undefined) => {
      await tryOrToast(
        async () => {
          koruStore.commit(koruStoreTypes.mutations.loadingStart);
          await moduleRef.value.onCreateAction(parent);
        },
        "create",
        t,
        () => koruStore.commit(koruStoreTypes.mutations.loadingStop)
      );
    };

    const updateAction = async (document: Record<string, unknown>) => {
      await tryOrToast(
        async () => {
          koruStore.commit(koruStoreTypes.mutations.loadingStart);
          await moduleRef.value.onUpdateAction(document);
        },
        "update",
        t,
        () => koruStore.commit(koruStoreTypes.mutations.loadingStop),
        false
      );
    };

    const deleteAction = async (document: Record<string, unknown>) => {
      await tryOrToast(
        async () => {
          koruStore.commit(koruStoreTypes.mutations.loadingStart);
          await moduleRef.value.onDeleteAction(document);

          await moduleRef.value.onLoadAction();
        },
        "delete",
        t,
        () => koruStore.commit(koruStoreTypes.mutations.loadingStop)
      );
    };

    const showDeleteItemDialog = ref(false);
    const deleteItem = ref({});

    const deleteItemAction = () => {
      showDeleteItemDialog.value = false;
      deleteAction(deleteItem.value);
    };

    const getRowCommands = (document: FirestoreDocument) => {
      const renderRowCommands: RowCommandInterface[] = [];

      if (moduleRef.value.canCreate && (requiredModule == undefined || userCanCreate(requiredModule))) {
        renderRowCommands.push({
          label: t("buttons.create"),
          icon: "pi pi-plus",
          command: (object: Record<string, unknown>) => {
            createAction(object.reference);
          },
        } as RowCommandInterface);
      }

      if (moduleRef.value.canEdit && (requiredModule == undefined || userCanUpdate(requiredModule, document))) {
        renderRowCommands.push({
          label: t("buttons.edit"),
          icon: "pi pi-pencil",
          command: (object: Record<string, unknown>) => {
            updateAction(object.reference as Record<string, unknown>);
          },
        } as RowCommandInterface);
      }

      if (moduleRef.value.canDelete && (requiredModule == undefined || userCanDelete(requiredModule, document))) {
        renderRowCommands.push({
          label: t("buttons.delete"),
          icon: "pi pi-trash",
          command: (object: Record<string, unknown>) => {
            deleteItem.value = object.reference as Record<string, unknown>;
            showDeleteItemDialog.value = true;
          },
        } as RowCommandInterface);
      }

      if (moduleRef.value.rowCommands !== undefined && moduleRef.value.rowCommands.length > 0) {
        const rowCommandsLength = renderRowCommands.length;
        for (const rowCommand of moduleRef.value.rowCommands) {
          if (rowCommand.condition(document) == true) {
            if (renderRowCommands.length == rowCommandsLength && rowCommandsLength > 0) {
              renderRowCommands.push({ label: "separator" } as RowCommandInterface);
            }
            renderRowCommands.push(rowCommand);
          }
        }
      }

      if (renderRowCommands.length === 0) {
        renderRowCommands.push({
          label: t("buttons.noAction"),
          icon: "pi pi-times",
          condition: () => true,
          command: () => {
            return;
          },
        } as RowCommandInterface);
      }

      return renderRowCommands;
    };

    koruEventBus.on("koruTreeReload", () => Promise.resolve(handleLoad()));

    onMounted(() => {
      handleLoad();
    });

    return {
      createAction,
      deleteItemAction,
      deleteItem,
      getObjectValueWithField,
      getRowCommands,
      moduleRef,
      nodes,
      requiredModule,
      showDeleteItemDialog,
      t,
      userCanCreate,
    };
  },
});
