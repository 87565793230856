
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { usePrimeVue } from "primevue/config";

import { authStoreTypes } from "@/core/modules/auth/store/types";
import { LocaleInterface } from "../interfaces/Locale.interface";
import { localeModel } from "@/core/modules/locale/models/Locale.model";
import { useAuthStore } from "@/core/modules/auth/store";
import { User } from "@/core/modules/user/objects/User";
import { useKoruLocale } from "../";

import { dePrimevue } from "@/core/locales/primevue/de";
import { enPrimevue } from "@/core/locales/primevue/en";
import { frPrimevue } from "@/core/locales/primevue/fr";
import { itPrimevue } from "@/core/locales/primevue/it";

export default defineComponent({
  name: "LocaleSelector",
  setup() {
    const { t } = useKoruLocale();
    const authStore = useAuthStore();
    const primeVue = usePrimeVue();
    const useLocale = useI18n();

    const locales: LocaleInterface[] = localeModel.getLocales();
    const selectedLocale: Ref<LocaleInterface> = ref(localeModel.getSelectedLocale());

    const selectLocale = (locale: LocaleInterface): void => {
      selectedLocale.value = locale;
      localeModel.setSelectedLocale(locale);
      useLocale.locale.value = locale.name;
      if (locale.name === "de") {
        primeVue.config.locale = dePrimevue;
      } else if (locale.name === "en") {
        primeVue.config.locale = enPrimevue;
      } else if (locale.name === "fr") {
        primeVue.config.locale = frPrimevue;
      } else if (locale.name === "it") {
        primeVue.config.locale = itPrimevue;
      }
    };

    onMounted(() => {
      const user: User = authStore.getter(authStoreTypes.getters.getUser);
      for (const locale of locales) {
        if (locale.name == user.detail.locale) {
          selectLocale(locale);
          break;
        }
      }
    });

    return {
      locales,
      primeVue,
      selectedLocale,
      selectLocale,
      t,
      useLocale,
    };
  },
});
