import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "app-name" }
const _hoisted_3 = { class: "layout-menu-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SubMenu = _resolveComponent("SubMenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: "layout-sidebar",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMenuClick && _ctx.onMenuClick(...args)))
  }, [
    _createVNode(_component_router_link, {
      to: "/",
      class: "logo"
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          id: "app-logo",
          class: "logo-image",
          src: _ctx.sidebarLogoUrl,
          alt: ""
        }, null, 8, _hoisted_1),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.koruConfig.app.logos.sidebarTitle), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_SubMenu, {
        items: _ctx.model,
        layoutMode: _ctx.layoutMode,
        parentMenuItemActive: true,
        menuActive: _ctx.active,
        mobileMenuActive: _ctx.mobileMenuActive,
        root: true,
        onMenuitemClick: _ctx.onMenuItemClick,
        onRootMenuitemClick: _ctx.onRootMenuItemClick
      }, null, 8, ["items", "layoutMode", "menuActive", "mobileMenuActive", "onMenuitemClick", "onRootMenuitemClick"])
    ])
  ]))
}