import { KoruFile } from "@/core/modules/file/objects/KoruFile";
import { StorageModelInterface } from "../interfaces/StorageModel.interface";

import { deleteFile, fileExists, getBase64, getDownloadLink, upload, uploadBase64, uploadFile } from "./methods";

export class StorageModel implements StorageModelInterface {
  public async deleteFile(path: string, name: string): Promise<void> {
    return deleteFile(path, name);
  }

  public async fileExists(path: string, name: string): Promise<boolean> {
    return fileExists(path, name);
  }

  public async getDownloadLink(path: string, name: string): Promise<string> {
    return getDownloadLink(path, name);
  }

  public async uploadFile(path: string, file: File, name: string | undefined): Promise<KoruFile> {
    return uploadFile(path, file, name);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////

  public async getBase64(path: string, name: string): Promise<string> {
    return getBase64(path, name);
  }

  public async upload(path: string, name: string, file: File, callback: (progress: number) => void): Promise<void> {
    return upload(path, name, file, callback);
  }

  public async uploadBase64(path: string, name: string, base64File: string): Promise<void> {
    return uploadBase64(path, name, base64File);
  }
}

export const storageModel: StorageModel = new StorageModel();
