import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_KoruActionButton = _resolveComponent("KoruActionButton")!
  const _component_KoruSplitButton = _resolveComponent("KoruSplitButton")!
  const _component_KoruBreadcrumb = _resolveComponent("KoruBreadcrumb")!
  const _component_KoruContainer = _resolveComponent("KoruContainer")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.moduleRef.breadcrumbs.value)
      ? (_openBlock(), _createBlock(_component_KoruBreadcrumb, {
          key: 0,
          items: _ctx.moduleRef.breadcrumbs.value
        }, {
          buttons: _withCtx(() => [
            _withDirectives(_createVNode(_component_Button, {
              icon: "pi pi-times",
              class: "p-button-warning",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.userCanUpdate(_ctx.requiredModule) ? _ctx.confirmClose($event) : _ctx.closeEditor()))
            }, null, 512), [
              [
                _directive_tooltip,
                _ctx.t('buttons.close'),
                void 0,
                { bottom: true }
              ]
            ]),
            _renderSlot(_ctx.$slots, "buttons"),
            (_ctx.moduleRef.saveCommands !== undefined && _ctx.moduleRef.saveCommands.length > 0)
              ? _withDirectives((_openBlock(), _createBlock(_component_KoruActionButton, {
                  key: 0,
                  class: "ml-4",
                  model: _ctx.getSaveCommands(),
                  reference: _ctx.moduleRef.document.value
                }, null, 8, ["model", "reference"])), [
                  [
                    _directive_tooltip,
                    _ctx.t('gen.actions'),
                    void 0,
                    { bottom: true }
                  ]
                ])
              : _createCommentVNode("", true),
            (
          _ctx.moduleRef.canCreate &&
          ((_ctx.userCanCreate(_ctx.requiredModule) && _ctx.moduleRef.document.value.id === 'new') || _ctx.userCanUpdate(_ctx.requiredModule, _ctx.moduleRef.document.value)) &&
          (_ctx.moduleRef.submitCommands === undefined || _ctx.moduleRef.submitCommands.length === 0)
        )
              ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                  key: 1,
                  icon: "pi pi-save",
                  type: "button",
                  class: "p-button-success ml-4",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSubmit()))
                }, null, 512)), [
                  [
                    _directive_tooltip,
                    _ctx.t('buttons.save'),
                    void 0,
                    { bottom: true }
                  ]
                ])
              : _createCommentVNode("", true),
            (
          _ctx.moduleRef.canCreate &&
          ((_ctx.userCanCreate(_ctx.requiredModule) && _ctx.moduleRef.document.value.id === 'new') || _ctx.userCanUpdate(_ctx.requiredModule, _ctx.moduleRef.document.value)) &&
          _ctx.moduleRef.submitCommands !== undefined &&
          _ctx.moduleRef.submitCommands.length > 0
        )
              ? _withDirectives((_openBlock(), _createBlock(_component_KoruSplitButton, {
                  key: 2,
                  icon: "pi pi-save",
                  class: "p-button-success ml-4",
                  defaultAction: _ctx.handleSubmit,
                  model: _ctx.moduleRef.submitCommands,
                  reference: _ctx.moduleRef.document.value
                }, null, 8, ["defaultAction", "model", "reference"])), [
                  [
                    _directive_tooltip,
                    _ctx.t('buttons.save'),
                    void 0,
                    { bottom: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["items"]))
      : _createCommentVNode("", true),
    _createVNode(_component_KoruContainer, null, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.handleSubmit()), ["prevent"])),
          onKeydown: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleKeydown($event)))
        }, [
          _renderSlot(_ctx.$slots, "form")
        ], 32)
      ]),
      _: 3
    })
  ], 64))
}