import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { BatchInterface } from "./interfaces/Batch.interface";
import { EditInterface } from "./interfaces/Edit.interface";
import { koruConfig } from "../config";

import { delay, showDebug } from "@/core/modules/helpers";

const MAX_EDITS = 200;
const DELAY_MILLIS = 2000;
const LONG_DELAY_THRESHOLD = 9000;
const LONG_DELAY_MILLIS = 20000;

class KoruBatch implements BatchInterface {
  edits: EditInterface[] = [];

  set<T extends firebase.firestore.DocumentData>(documentRef: firebase.firestore.DocumentReference<T>, data: Partial<T>) {
    this.edits.push({
      type: "set",
      reference: documentRef,
      data: data,
    });
  }

  update<T extends firebase.firestore.DocumentData>(documentRef: firebase.firestore.DocumentReference<T>, data: Partial<T>) {
    this.edits.push({
      type: "update",
      reference: documentRef,
      data: data,
    });
  }

  delete(documentRef: firebase.firestore.DocumentReference) {
    this.edits.push({
      type: "delete",
      reference: documentRef,
      data: undefined,
    });
  }

  async commit(): Promise<void> {
    try {
      let batch: firebase.firestore.WriteBatch = firebase.firestore().batch();

      let index = 0;
      let counter = 0;
      let longCounter = 0;
      for (const edit of this.edits) {
        if (edit.type == "set") {
          batch.set(edit.reference, edit.data);
        } else if (edit.type == "update") {
          batch.update(edit.reference, edit.data as firebase.firestore.UpdateData);
        } else if (edit.type == "delete") {
          batch.delete(edit.reference);
        } else {
          continue;
        }

        index++;
        counter++;
        longCounter++;
        if (counter >= MAX_EDITS) {
          if (longCounter >= LONG_DELAY_THRESHOLD) {
            await delay(LONG_DELAY_MILLIS);
            longCounter = 0;
          }
          if (koruConfig.app.debug === true) showDebug("KoruBatch", `committing ${counter} operations (${index}/${this.edits.length})`);
          await batch.commit();
          await delay(DELAY_MILLIS);
          batch = firebase.firestore().batch();
          counter = 0;
        }
      }

      if (counter > 0) {
        if (longCounter >= LONG_DELAY_THRESHOLD) await delay(LONG_DELAY_MILLIS);
        if (koruConfig.app.debug === true) showDebug("KoruBatch", `committing ${counter} operations (${index}/${this.edits.length})`);
        await batch.commit();
      }

      this.edits = [];
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }
}

export const koruBatch = new KoruBatch();
