export async function base64StringToPdf(base64String: string, fileName: string): Promise<File> {
  const blob: Blob = await (await fetch(`data:application/pdf;base64,${base64String}`, { headers: { "Content-Type": "application/pdf" } })).blob();
  return new File([blob], fileName, { type: "application/pdf" });
}

export function blobToBase64(blob: Blob): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result === null) {
        reject(undefined);
      } else {
        const result: string = reader.result as string;
        resolve(result.slice(result.indexOf(",") + 1));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export const convertBlobToBase64 = (blob: Blob): Promise<unknown> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

export const fetchAsBlob = async (url: string): Promise<Blob> => {
  const response: Response = await fetch(url);
  return response.blob();
};
