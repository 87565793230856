export const it = {
  dashboard: {
    draftExaminationByCompany: {
      count: "{count} visita medica da effettuare | {count} visite mediche da effettuare",
      title: "Visite mediche da effettuare per azienda",
    },
    draftExaminationByDoctor: {
      count: "{count} visita medica da effettuare | {count} visite mediche da effettuare",
      title: "Visite mediche da effettuare per medico",
    },
    expiringCompanyDoc: {
      count: "{count} documento aziendale scade in meno di {days} giorni | {count} documenti aziendali scadono in meno di {days} giorni",
      title: "Documenti azienda in scadenza",
    },
    expiringContract: {
      count: "{count} nomina scade in meno di {days} giorni | {count} nomine scadono in meno di {days} giorni",
      title: "Nomine in scadenza",
    },
    expiringDoctorDoc: {
      count: "{count} documento del medico scade in meno di {days} giorni | {count} documenti del medico scadono in meno di {days} giorni",
      title: "Documenti medico in scadenza",
    },
    expiringEmployeeDoc: {
      count: "{count} documento del lavoratore scade in meno di {days} giorni | {count} documenti del lavoratore scadono in meno di {days} giorni",
      title: "Documenti lavoratore in scadenza",
    },
    lastExaminationByCompany: {
      count: "{count} visita medica effettuata | {count} visite mediche effettuate",
      title: "Visite mediche completate negli ultimi {days} giorni per azienda",
    },
    lastExaminationByDoctor: {
      count: "{count} visita medica effettuata | {count} visite mediche effettuate",
      title: "Visite mediche completate negli ultimi {days} giorni per medico",
    },
    noExaminations: "Nessuna visita medica da visualizzare",
    todayExaminationByCompany: {
      count: "{count} visita medica da effettuare | {count} visite mediche da effettuare",
      title: "Visite mediche di oggi",
    },
    tomorrowExaminationByCompany: {
      count: "{count} visita medica da effettuare | {count} visite mediche da effettuare",
      title: "Visite mediche di domani",
    },
  },
};
