import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { Clause } from "./Clause";
import { FirestoreDocument } from "./FirestoreDocument";
import { FirestoreModelInterface } from "../interfaces/FirestoreModel.interface";
import { FirestoreSorter } from "./FirestoreSorter";
import { LockPolicy } from "./LockPolicy";
import { SortCriteria } from "./SortCriteria";
import { User } from "@/core/modules/user/objects/User";

import { runBeforeDeleteFunction } from "@/core/modules/helpers";
import { userCanCreate, userCanDelete, userCanReadAll, userCanReadOwned, userCanUpdate } from "@/core/modules/user/helpers";
import { getCollectionReference, getCollectionGroupReference } from "../helpers";

export class FirestoreModel<T extends FirestoreDocument> implements FirestoreModelInterface<T> {
  public beforeDeleteFunction: string | undefined = undefined;
  public collectionName: string;
  public documentSubscriptionClose: () => void = () => null;
  public firestoreConverter: firebase.firestore.FirestoreDataConverter<T>;
  public lockPolicy: LockPolicy = LockPolicy.None;
  public parentCollectionName: string | undefined = undefined;
  public roleModule: string;

  public constructor(newIstance: () => T, collectionName: string, lockPolicy: LockPolicy, roleModule: string) {
    this.collectionName = collectionName;
    this.lockPolicy = lockPolicy;
    this.roleModule = roleModule;

    this.firestoreConverter = this.createFirestoreConverter(newIstance);
  }

  public async getDocuments(sortCriterias: SortCriteria[] = [], parentId?: string): Promise<T[]> {
    try {
      let documents: T[] = [];

      if (userCanReadAll(this.roleModule) === true) {
        // user can read all documents
        documents = await this.getOnlineAllDocuments(parentId);
      } else if (userCanReadOwned(this.roleModule) === true) {
        // user can read only documents created by him
        documents = await this.getOnlineOwnDocuments(parentId);
      } else {
        // user can read only documents with explicit read rights
        documents = await this.getOnlineDocumentsWithRight(parentId);
      }

      if (sortCriterias.length === 0) return documents;

      // sort documents
      const firestoreSorter: FirestoreSorter<T> = new FirestoreSorter(documents);
      firestoreSorter.setSortCriterias(sortCriterias);
      return firestoreSorter.sort();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  public async getDocument(firestoreDocumentId: string, parentId?: string): Promise<T> {
    try {
      // get online document
      const pathReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = this.getPathReference(parentId);
      const doc: firebase.firestore.DocumentSnapshot<T> = await pathReference.withConverter(this.firestoreConverter).doc(firestoreDocumentId).get();
      if (doc.exists === false) throw new Error(`#${firestoreDocumentId} not found in collection ${this.collectionName}`);

      return doc.data() as T;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  public async createDocument(firestoreDocument: T, parentId?: string): Promise<string> {
    try {
      // check if user can create document
      if (userCanCreate(this.roleModule) === false) throw new Error(`Unable to create document in collection ${this.collectionName}`);

      // create online document
      const pathReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = this.getPathReference(parentId);
      firestoreDocument.setSearchKeys();
      firestoreDocument.setTimestampFields("create");
      let newDocId: string;
      if (firestoreDocument.id === "new") {
        newDocId = (await pathReference.withConverter(this.firestoreConverter).add(firestoreDocument)).id;
      } else {
        newDocId = firestoreDocument.id;
        await pathReference.withConverter(this.firestoreConverter).doc(firestoreDocument.id).set(firestoreDocument);
      }

      return newDocId;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  public async updateDocument(firestoreDocument: T, parentId?: string): Promise<void> {
    try {
      // check if user can update the document
      if (userCanUpdate(this.roleModule, firestoreDocument) === false)
        throw new Error(`Unable to update document #${firestoreDocument.id} in collection ${this.collectionName}`);

      // check if document is locked
      if (this.lockPolicy === LockPolicy.DiscardUnsyncedChanges) {
        const oldFirestoreDocument: T = await this.getDocument(firestoreDocument.id, parentId);
        if (firestoreDocument.hasChangedFrom(oldFirestoreDocument)) {
          throw new Error("sync");
        }
      }

      const pathReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = this.getPathReference(parentId);
      firestoreDocument.setSearchKeys();
      firestoreDocument.setTimestampFields("update");
      await pathReference.withConverter(this.firestoreConverter).doc(firestoreDocument.id).set(firestoreDocument);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  public async deleteDocument(firestoreDocument: T, parentId?: string): Promise<void> {
    try {
      // check if user can delete the document
      if (userCanDelete(this.roleModule, firestoreDocument) === false)
        throw new Error(`Unable to delete document #${firestoreDocument.id} in collection ${this.collectionName}`);

      // check if document is locked
      if (this.beforeDeleteFunction !== undefined) await runBeforeDeleteFunction(this.beforeDeleteFunction, firestoreDocument.id);

      // TODOSOONDELETE

      // delete online document
      const pathReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = this.getPathReference(parentId);
      await pathReference.doc(firestoreDocument.id).delete();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  public async searchDocuments(searchText: string, sortCriterias: SortCriteria[], clauses?: Clause[]): Promise<T[]> {
    try {
      // user can read all documents
      if (userCanReadAll(this.roleModule) === true) return this.searchOnlineAllDocuments(searchText, sortCriterias, clauses);
      // user can read only documents created by him
      if (userCanReadOwned(this.roleModule) === true) return this.searchOnlineOwnDocuments(searchText, sortCriterias, clauses);
      // user can read only documents with explicit read rights
      return this.searchOnlineDocumentsWithRight(searchText, sortCriterias);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  public getPathReference(parentId?: string): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
    let collectionPath: string = this.collectionName;
    if (this.parentCollectionName !== undefined && parentId !== undefined) {
      collectionPath = `${this.parentCollectionName}/${parentId}/${this.collectionName}`;
    }
    return getCollectionReference(collectionPath);
  }

  public getGroupPathReference(): firebase.firestore.Query<firebase.firestore.DocumentData> {
    return getCollectionGroupReference(this.collectionName);
  }

  public getNewId(parentId?: string): string {
    return this.getPathReference(parentId).doc().id;
  }

  public createFirestoreConverter(newIstance: () => T): firebase.firestore.FirestoreDataConverter<T> {
    return {
      fromFirestore: function (
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions | undefined
      ): T {
        const data = snapshot.data(options);
        const firestoreDocument: T = newIstance();
        firestoreDocument.fromFirestore(data, snapshot.id, snapshot.ref);
        return firestoreDocument;
      },
      toFirestore: function (firestoreDocument: T): Record<string, unknown> {
        return firestoreDocument.toFirestore();
      },
    };
  }

  // get documents functions

  public async getOnlineAllDocuments(parentId?: string): Promise<T[]> {
    const pathReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = this.getPathReference(parentId);

    const snapshot: firebase.firestore.QuerySnapshot<T> = await pathReference.withConverter(this.firestoreConverter).get();

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  }

  public async getOnlineOwnDocuments(parentId?: string): Promise<T[]> {
    const user: User = authStore.getter(authStoreTypes.getters.getUser);

    const pathReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = this.getPathReference(parentId);

    const snapshot: firebase.firestore.QuerySnapshot<T> = await pathReference
      .withConverter(this.firestoreConverter)
      .where("createdBy", "==", user.id)
      .get();

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  }

  public async getOnlineDocumentsWithRight(parentId?: string): Promise<T[]> {
    const user: User = authStore.getter(authStoreTypes.getters.getUser);

    const pathReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = this.getPathReference(parentId);

    const query: firebase.firestore.Query<T> = pathReference.withConverter(this.firestoreConverter);

    if (user !== undefined) query.where("rights.read", "array-contains", user.id);

    const snapshot: firebase.firestore.QuerySnapshot<T> = await query.get();

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  }

  public async searchOnlineAllDocuments(searchText: string, sortCriterias: SortCriteria[], clauses?: Clause[]): Promise<T[]> {
    const pathReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = this.getPathReference();

    let query: firebase.firestore.Query<T> = pathReference.withConverter(this.firestoreConverter).where("searchKeys", "array-contains", searchText);

    if (clauses !== undefined) {
      for (const clause of clauses) {
        query = query.where(clause.field, clause.condition, clause.value);
      }
    }

    for (const sortCriteria of sortCriterias) {
      query = query.orderBy(sortCriteria.field, sortCriteria.direction);
    }

    const snapshot: firebase.firestore.QuerySnapshot<T> = await query.get();
    if (snapshot == undefined || snapshot.empty) return [];
    return snapshot.docs.map((doc) => doc.data());
  }

  public async searchOnlineOwnDocuments(searchText: string, sortCriterias: SortCriteria[], clauses?: Clause[]): Promise<T[]> {
    const user: User = authStore.getter(authStoreTypes.getters.getUser);

    const pathReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = this.getPathReference();

    let query: firebase.firestore.Query<T> = pathReference
      .withConverter(this.firestoreConverter)
      .where("createdBy", "==", user.id)
      .where("searchKeys", "array-contains", searchText);

    if (clauses !== undefined) {
      for (const clause of clauses) {
        query = query.where(clause.field, clause.condition, clause.value);
      }
    }

    for (const sortCriteria of sortCriterias) {
      query = query.orderBy(sortCriteria.field, sortCriteria.direction);
    }

    const snapshot: firebase.firestore.QuerySnapshot<T> = await query.get();
    if (snapshot == undefined || snapshot.empty) return [];
    return snapshot.docs.map((doc) => doc.data());
  }

  public async searchOnlineDocumentsWithRight(searchText: string, sortCriterias: SortCriteria[], clauses?: Clause[]): Promise<T[]> {
    const user: User = authStore.getter(authStoreTypes.getters.getUser);

    const pathReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = this.getPathReference();

    let query: firebase.firestore.Query<T> = pathReference.withConverter(this.firestoreConverter).where("rights.read", "array-contains", user.id);

    if (clauses !== undefined) {
      for (const clause of clauses) {
        query = query.where(clause.field, clause.condition, clause.value);
      }
    }

    const snapshot: firebase.firestore.QuerySnapshot<T> = await query.get();

    if (snapshot == undefined || snapshot.empty) return [];

    let documents: T[] = snapshot.docs.map((doc) => doc.data());
    documents = documents.filter((doc) => doc.searchKeys.includes(searchText));

    const firestoreSorter: FirestoreSorter<T> = new FirestoreSorter(documents);
    firestoreSorter.setSortCriterias(sortCriterias);
    return firestoreSorter.sort();
  }
}
