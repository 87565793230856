import { ActionTree } from "vuex";
import { NavigationFailure } from "vue-router";

import { koruRouter } from "@/core/modules/router";
import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";
import { koruStoreTypes } from "./types";

export const actions: ActionTree<KoruStateInterface, KoruStateInterface> = {
  async [koruStoreTypes.actions.navigateTo](context, route: string): Promise<void | NavigationFailure | undefined> {
    return await koruRouter.push(route);
  },
  async [koruStoreTypes.actions.navigateUndercoverTo](context, route: string): Promise<void | NavigationFailure | undefined> {
    return await koruRouter.replace(route);
  },
  async [koruStoreTypes.actions.navigateWithConfirm](context): Promise<void | NavigationFailure | undefined> {
    try {
      const route = context.state.route;
      context.commit(koruStoreTypes.mutations.closeEditingDialog);
      if (route == undefined) return;

      context.commit(koruStoreTypes.mutations.editingStop);
      const result = await koruRouter.push(route);
      return result;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [koruStoreTypes.actions.navigateBack](): Promise<void> {
    koruRouter.back();
  },
};
