import { MutationTree } from "vuex";

import { AuthStateInterface } from "../interfaces/AuthState.interface";
import { authStoreTypes } from "./types";
import { User } from "@/core/modules/user/objects/User";

export const mutations: MutationTree<AuthStateInterface> = {
  [authStoreTypes.mutations.loadingStart]: (state: AuthStateInterface): void => {
    state.loading = true;
  },
  [authStoreTypes.mutations.loadingStop]: (state: AuthStateInterface): void => {
    state.loading = false;
  },
  [authStoreTypes.mutations.loginSuccess]: (state: AuthStateInterface, user: User): void => {
    state.isLoggedIn = true;
    state.user = user;
    state.loading = false;
    localStorage.setItem("koruLiteUser", JSON.stringify(user.toOfflineCache()));
  },
  [authStoreTypes.mutations.loginError]: (state: AuthStateInterface): void => {
    state.isLoggedIn = false;
    state.user = undefined;
    state.loading = false;
  },
  [authStoreTypes.mutations.logoutSuccess]: (state: AuthStateInterface): void => {
    state.isLoggedIn = false;
    state.user = undefined;
    state.loading = false;
  },
};
