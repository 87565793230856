import { RouteRecordRaw } from "vue-router";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/core/layout/NotFound.vue"),
  },
  {
    path: "/access-denied",
    name: "AccessDenied",
    component: () => import("@/core/layout/AccessDenied.vue"),
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("@/core/layout/Error.vue"),
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () => import("@/core/layout/Maintenance.vue"),
  },
];
