
import { defineComponent } from "vue";

import { useKoruConfig } from "@/core/modules/config";
import { useKoruLocale } from "@/core/modules/locale";

export default defineComponent({
  name: "Footer",
  setup() {
    const { t } = useKoruLocale();
    const koruConfig = useKoruConfig();

    const footerLogoUrl: string =
      koruConfig.app.logos.footerImage === "features" ? "/assets/features/images/logo-dark.svg" : "/assets/core/images/koru-logo-dark.svg";

    return {
      footerLogoUrl,
      koruConfig,
      t,
    };
  },
});
