
import { defineComponent, Ref, ref } from "vue";

import { authStoreTypes } from "@/core/modules/auth/store/types";
import { koruEventBus } from "@/core/modules/event-bus";
import { koruStoreTypes } from "@/core/modules/store/types";
import { NavigationItemInterface } from "@/core/modules/navigation/interfaces/NavigationItem.interface";
import { useAuthStore } from "@/core/modules/auth/store";
import { useKoruLocale } from "@/core/modules/locale";
import { useKoruStore } from "@/core/modules/store";
import { User } from "@/core/modules/user/objects/User";

import { userHasAnyViewUIRight } from "@/core/modules/user/helpers";

export default defineComponent({
  name: "submenu",
  emits: ["root-menuitem-click", "menuitem-click"],
  props: {
    items: Array<NavigationItemInterface>,
    layoutMode: String,
    menuActive: Boolean,
    mobileMenuActive: Boolean,
    root: {
      type: Boolean,
      default: false,
    },
    parentMenuItemActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useKoruLocale();
    const koruStore = useKoruStore();
    const authStore = useAuthStore();
    const user: User = authStore.getter(authStoreTypes.getters.getUser);

    const activeIndex: Ref<number | undefined> = ref(undefined);

    const hasVisibleChildren = (item: NavigationItemInterface): boolean => {
      if (item.items == undefined) {
        return false;
      }
      for (let itemsIndex = 0; itemsIndex < item.items.length; itemsIndex++) {
        const child: NavigationItemInterface = item.items[itemsIndex];
        if (isChildVisible(child)) {
          return true;
        }
      }
      return false;
    };

    const isChildVisible = (item: NavigationItemInterface): boolean => {
      if (item.roleModules === undefined) return false;

      return userHasAnyViewUIRight(user, item.roleModules);
    };

    const hasZeroChildren = (item: NavigationItemInterface): boolean => {
      return item.items?.length === 0 && (item.roleModules === undefined || userHasAnyViewUIRight(user, item.roleModules));
    };

    const isLastItem = (index: number): boolean => {
      return index == Object.keys(props.items as Array<NavigationItemInterface>).length - 1;
    };

    return {
      activeIndex,
      hasVisibleChildren,
      hasZeroChildren,
      isChildVisible,
      isLastItem,
      koruStore,
      koruStoreTypes,
      t,
    };
  },
  mounted(): void {
    koruEventBus.on("reset-active-index", () => {
      if (this.isSlim()) {
        this.activeIndex = undefined;
      }
      return Promise.resolve();
    });
  },
  methods: {
    onMenuItemClick(event: Event, item: NavigationItemInterface, index: number): void {
      if (item.to) {
        event.preventDefault();
        this.koruStore.dispatch(koruStoreTypes.actions.navigateTo, item.to);
      }
      if (item.items) {
        event.preventDefault();
      }
      if (this.root) {
        this.$emit("root-menuitem-click", {
          originalEvent: event,
        });
      }
      if (item.items) {
        this.activeIndex = index === this.activeIndex ? undefined : index;
      }
      this.$emit("menuitem-click", {
        originalEvent: event,
        item: item,
      });
    },
    onMenuItemMouseEnter(index: number): void {
      if (this.root && this.menuActive && this.layoutMode === "slim" && !this.isMobile()) {
        this.activeIndex = index;
      }
    },
    isMobile(): boolean {
      return window.innerWidth <= 991;
    },
    isSlim(): boolean {
      return this.layoutMode === "slim";
    },
  },
});
