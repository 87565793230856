import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { koruBatch } from "@/core/modules/batch";
import { LocaleInterface } from "@/core/modules/locale/interfaces/Locale.interface";
import { User } from "@/core/modules/user/objects/User";
import { UserDetail } from "@/core/modules/user/objects/UserDetail";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const setSelectedLocale = async (locale: LocaleInterface): Promise<void> => {
  try {
    const user: User = authStore.getter(authStoreTypes.getters.getUser);

    const userDetail: UserDetail = user.detail;
    userDetail.locale = locale.name;

    koruBatch.update(getCollectionReference("users").doc(user.id), { detail: userDetail.toFirestore() });

    return koruBatch.commit();
  } catch (error: unknown) {
    console.error(error);
    throw new Error((error as Error).message);
  }
};
