import { LocaleInterface } from "../interfaces/Locale.interface";
import { LocaleModelInterface } from "../interfaces/LocaleModel.interface";

import { getSelectedLocale, getLocales, setSelectedLocale } from "./methods";

export class LocaleModel implements LocaleModelInterface {
  public getSelectedLocale(): LocaleInterface {
    return getSelectedLocale();
  }

  public getLocales(): LocaleInterface[] {
    return getLocales();
  }

  public async setSelectedLocale(locale: LocaleInterface): Promise<void> {
    return setSelectedLocale(locale);
  }
}

export const localeModel: LocaleModel = new LocaleModel();
