import { FirestoreDocumentRightInterface } from "../interfaces/FirestoreDocumentRight.interface";

import { StringArrayField } from "@/core/fields";

export class FirestoreDocumentRight implements FirestoreDocumentRightInterface {
  public read: string[] = [];
  public update: string[] = [];
  public delete: string[] = [];

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): FirestoreDocumentRight {
    this.read = StringArrayField.fromFirestore(data.read);
    this.update = StringArrayField.fromFirestore(data.update);
    this.delete = StringArrayField.fromFirestore(data.delete);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    return {
      read: StringArrayField.toFirestore(this.read),
      update: StringArrayField.toFirestore(this.update),
      delete: StringArrayField.toFirestore(this.delete),
    };
  }

  public fromOfflineCache(data: Record<string, unknown>): FirestoreDocumentRight {
    this.read = StringArrayField.fromOfflineCache(data.read);
    this.update = StringArrayField.fromOfflineCache(data.update);
    this.delete = StringArrayField.fromOfflineCache(data.delete);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    return {
      read: StringArrayField.toOfflineCache(this.read),
      update: StringArrayField.toOfflineCache(this.update),
      delete: StringArrayField.toOfflineCache(this.delete),
    };
  }

  public hasRights(): boolean {
    return this.read.length > 0 || this.update.length > 0 || this.delete.length > 0;
  }
}
