import { ConfigInterface } from "./interfaces/Config.interface";

import apiDev from "@/data/config/api-dev.json";
import apiProd from "@/data/config/api-prod.json";
import app from "@/data/config/app.json";
import firebaseDev from "@/data/config/firebase-dev.json";
import firebaseProd from "@/data/config/firebase-prod.json";
import landscapes from "@/data/config/landscapes.json";
import liteDev from "@/data/config/lite-dev.json";
import liteProd from "@/data/config/lite-prod.json";
import storage from "@/data/config/storage.json";
import urlDev from "@/data/config/url-dev.json";
import urlProd from "@/data/config/url-prod.json";

let env = "development";
if (process.env && process.env.VUE_APP_ENVIRONMENT) {
  env = process.env.VUE_APP_ENVIRONMENT.trim().toLowerCase();
}

export const environment: string = env;

const configsMap: Map<string, ConfigInterface> = new Map<string, ConfigInterface>([
  [
    "development",
    {
      api: apiDev,
      app: app,
      firebase: firebaseDev,
      landscapes: landscapes,
      lite: liteDev,
      storage: storage,
      url: urlDev,
    },
  ],
  [
    "production",
    {
      api: apiProd,
      app: app,
      firebase: firebaseProd,
      landscapes: landscapes,
      lite: liteProd,
      storage: storage,
      url: urlProd,
    },
  ],
]);

if (!configsMap.has(env)) {
  throw Error(`Could not find config for VUE_APP_ENVIRONMENT key "${env}"`);
}

export const koruConfig: ConfigInterface = configsMap.get(env) as ConfigInterface;

export const useKoruConfig = (): ConfigInterface => {
  return koruConfig;
};
