export const de = {
  user: {
    activeTrue: "Aktiv",
    activeFalse: "Nicht aktiv",
    allUsers: "Alle Benutzer",
    email: "E-Mail",
    emailAddress: "E-Mail-Adresse",
    emailAddressError: "Bitte geben Sie eine gültige und unbenutzte E-Mail-Adresse ein",
    firstName: "Vorname",
    firstNameError: "Bitte geben Sie einen gültigen Namen ein",
    fullName: "Name",
    lastName: "Nachname",
    lastNameError: "Bitte geben Sie einen gültigen Nachnamen ein",
    name: "Name",
    notifications: {
      groupName: "Neue Benutzer",
    },
    registration: "Registrierung",
    role: "Rolle",
    roleError: "Wähle eine gültige Rolle",
    roleSelect: "Rolle auswählen",
    state: "Zustand",
    user: "Benutzer",
    users: "Benutzer",
  },
  navigation: {
    user: "Benutzer",
  },
  roleModules: {
    user: "Benutzer",
  },
};
