export const it = {
  examination: {
    attachments: {
      addAttachment: "Aggiungi allegato",
      attachments: "Allegati",
      file: "Documento",
      fileError: "Caricare un documento",
      name: "Nome",
      nameError: "Inserire un nome valido",
      requestAttachments: "Ottieni allegati",
    },
    addRow: "Nuova voce",
    billedMessage: "La visita medica è stata contabilizzata nella fattura",
    billedMessageOf: "del",
    branch: "Sede",
    branchError: "Selezionare una sede",
    branches: "Sedi",
    calculatedExamType: "Calcolato automaticamente",
    code: "Identificativo",
    company: "Azienda",
    companyAndBranch: "Azienda e sede",
    companyJudgmentFile: "Giudizio di idoneità per datore di lavoro",
    createExaminations: "Crea visite mediche",
    date: "Data",
    dateError: "Selezionare una data valida",
    datetime: "Data e ora",
    detail: "Dettagli",
    doctor: "Medico",
    doctorError: "Selezionare un medico",
    doctorTitle: "Dott.",
    dutyDate: "Data mansione",
    dutyName: "Mansione",
    dutyNameError: "Inserire una mansione valida",
    employee: "Lavoratore",
    employeeJudgmentFile: "Giudizio di idoneità per lavoratore",
    ending: "Conclusioni",
    endings: {
      additionalTestsRequired: "Ulteriori accertamenti richiesti dal medico competente",
      addLimitation: "Aggiungi limitazione",
      addPrescription: "Aggiungi prescrizione",
      companyDate: "Data trasmissione all'azienda",
      employeeDate: "Data tramissione al lavoratore",
      details: "Dettagli",
      ending: "Conclusioni diagnostiche",
      endingPaper: "Conclusioni",
      endingMeasures: "Provvedimenti del medico competente",
      getLastJudgment: "Giudizio precedente",
      judgment: "Giudizio di idoneità",
      judgmentDate: "Data giudizio",
      judgmentPaper: "Giudizio",
      judgments: {
        notEvaluated: "Non valutato",
        notExpressed: "Non espresso",
        fit: "Idoneo",
        fitWithLenses: "Idoneo con lenti",
        fitWithLimitations: "Idoneo con limitazioni",
        fitWithPrescriptions: "Idoneo con prescrizioni",
        fitWithPrescriptionsAndLimitations: "Idoneo con prescrizioni e limitazioni",
        unfit: "Non idoneo",
      },
      limitation: "Limitazione",
      limitations: "Limitazioni",
      limitationsNotes: "Note limitazioni",
      nextExaminationDate: "Data prossima visita",
      nextExaminationDateError: "Selezionare una data valida",
      notExpressedType: "Motivo",
      notExpressedTypes: {
        waitingDocuments: "In attesa di documentazione sanitaria",
        waitingTests: "In attesa di completare gli approfondimenti diagnostici",
      },
      prescription: "Prescrizione",
      prescriptions: "Prescrizioni",
      prescriptionsNotes: "Note prescrizioni",
      tags: "Conclusioni diagnostiche sintetiche",
      unfitType: "Durata",
      unfitTypes: {
        temporary: "Temporanea",
        permanent: "Permanente",
      },
    },
    examination: "Visita medica",
    examinationOf: "Visita medica di {parent}",
    examinationDate: "Data visita medica",
    examinations: "Visite mediche",
    examinationsOf: "Visite mediche di {parent}",
    expirationDate: "Scadenza entro la data",
    expireOn: "Scade il",
    familyHistories: {
      death: "Cause decesso",
      deathError: "Inserire le cause del decesso",
      diseases: "Patologie",
      diseasesError: "Inserire le patologie",
      kinship: "Parentela",
      kinshipError: "Selezionare la parentela",
    },
    familyHistory: "An. fam.",
    familyHistoryLong: "Anamnesi familiare",
    farHistory: "An. patol. rem.",
    farHistoryLong: "Anamnesi patologica remota",
    farHistories: {
      details: "Dettagli",
      disabilities: "Invalidità",
      disabilityType: "Tipo invalidità",
      disabilityTypeError: "Selezionare un tipo di invalidità",
      goodHealth: "Riferisce di aver sempre goduto di buona salute",
      injuries: "Traumi",
      notes: "Altre notizie utili ai fini anamnestici lavorativi",
      percent: "Percentuale",
      percentError: "Selezionare una percentuale valida",
      startYear: "Anno inizio",
      startYearError: "Selezionare un anno valido",
      surgery: "Interventi chirurgici",
      workAccidents: "Infortuni sul lavoro",
      workDiseases: "Malattie professionali",
    },
    frequency: "Periodicità",
    frequencyError: "Selezionare una periodicità",
    generateExaminations: "Genera visite mediche",
    healthRecordFile: "Cartella sanitaria",
    hireDate: "Data assunzione",
    invoice: "Fattura",
    noExaminationDate: "Nessuna scadenza",
    notes: "Note",
    notYetExaminated: "Non ancora visitato",
    of: "del",
    otherAttachments: "Altri allegati",
    otherExaminations: "Importa dati da altre visite mediche",
    paperField: "Elemento",
    paperValue: "Dettaglio",
    period: "Periodo",
    physicalExam: "Esame obiettivo",
    physicalExams: {
      abdomen: "Addome",
      bmi: "BMI (kg/m²)",
      breath: "Apparato respiratorio",
      details: "Dettagli",
      fc: "F.C. (b.m.)",
      generalCondition: "Condizioni generali",
      generalExam: "Esame generale",
      giordano: "Manovra Giordano",
      hearing: "Deficit uditivi",
      hearth: "Apparato cardiocircolatorio",
      height: "Altezza (cm)",
      musculoskeletal: "Sistema osteomuscolare",
      neuropsychic: "Sistema neuropsichico",
      other: "Altro",
      pao: "P.A.O. (mm/Hg)",
      sensory: "Sistema sensoriale",
      sight: "Deficit visivi",
      urogenital: "Sistema urogenitale",
      weight: "Peso (kg)",
    },
    physioHistory: "An. fis.",
    physioHistoryLong: "Anamnesi fisiologica",
    physioHistories: {
      abortions: "Aborti",
      allergies: "Allergie",
      allergiesList: "Dettagli",
      allergiesSymptoms: "Sintomi",
      beer: "Birra",
      completedPregnancies: "Gravidanze a termine",
      diuresis: "Diuresi",
      firstPeriod: "Menarca",
      food: "Alimentazione",
      details: "Dettagli",
      intestine: "Alvo",
      medicine: "Uso abituale di farmaci",
      menopause: "Menopausa",
      notes: "Altro",
      periodType: "Ciclo mestruale",
      school: "Scolarità",
      smokeAmount: "Quantità giornaliera",
      smokeFrom: "Dall'età",
      smokeTo: "All'età",
      smokeType: "Fumo",
      sons: "Figli",
      spirit: "Superalcolici",
      sport: "Attività sportiva",
      sportType: "Tipo di attività sportiva praticata",
      uncompletedPregnancies: "Gravidanze pre termine",
      wine: "Vino",
    },
    recentHistory: "An. pat. pros.",
    recentHistoryLong: "Anamnesi patologica prossima",
    recentHistories: {
      details: "Dettagli",
    },
    report: {
      birthDate: "Data di nascita",
      birthPlace: "Luogo di nascita",
      company: "Azienda",
      companyDate: "Trasmissione all'azienda",
      doctorSignature: "Firma del medico",
      duty: "Mansione",
      electronicNote: "La copia elettronica è conforme all'originale depositato presso lo studio medico",
      employee: "Lavoratore",
      employeeDate: "Trasmissione al lavoratore",
      employeeSignature: "Firma del lavoratore",
      examinationDate: "Data visita",
      examinationType: "Tipologia",
      fiscalCode: "Codice fiscale",
      frequency: "Periodicità",
      judgmentDate: "Data giudizio",
      judgmentTitle: "Giudizio di idoneità alla mansione specifica",
      nationality: "Nazionalità",
      nextExaminationPhrase:
        "Da sottoporre a nuova visita medica entro il {date} previa esecuzione degli accertamenti previsti dal programma di sorveglianza sanitaria predisposto dal medico competente ed in vostro possesso.",
      risksTitle: "Fattori di rischio valutati (come da Protocollo Sanitario)",
      testsTitle: "Accertamenti integrativi eseguiti",
      terms1:
        "Il lavoratore dichiara che quanto segnalato nell'anamnesi corrisponde al vero e si impegna ad informare il medico competente su future variazioni del proprio stato di salute. Dichiara di essere consapevole che il trattamento dei dati personali raccolti è finalizzato allo svolgimento dell'attività di Sorveglianza Sanitaria nel rispetto del D.Lgs 196/03 e Reg. UE 679/2016. Esprime il consenso al trattamento dei propri dati, autorizzandone l'eventuale trasmissione ad Enti che ne facciano richiesta, nel rispetto del segreto professionale. Il lavoratore dichiara che il medico competente gli ha fornito informazioni sul significato e sui risultati degli accertamenti sanitari cui è stato sottoposto, sui rischi lavorativi connessi alla mansione specifica, sulle misure di protezione, sul corretto utilizzo dei dispositivi di protezione individuale e sulla possibilità di ricevere copia della documentazione sanitaria previa richiesta al medico competente stesso. Il lavoratore ha accettato di sottoporsi al Protocollo Sanitario definito ed agli ulteriori accertamenti che il medico competente vorrà eventualmente richiedere. Il lavoratore attesta e sottoscrive di aver preso visione e di aver ricevuto copia della Comunicazione del Giudizio di Idoneità alla mansione specifica.",
      terms2:
        "Avverso il giudizio del medico competente - entro trenta giorni dalla comunicazione - è ammesso ricorso, del lavoratore o del datore di lavoro, all'Organo di Vigilanza territorialmente competente che dispone, dopo eventuali ulteriori accertamenti, la conferma, modifica o revoca del giudizio stesso (Art. 41 Comma 9 D. Lgs. 81/08 e S.M.I.).",
      title: "Comunicazione del GIUDIZIO DI IDONEITÀ alla MANSIONE SPECIFICA",
    },
    riskList: {
      addRisk: "Aggiungi rischio",
      details: "Dettagli",
      name: "Nome",
      nameError: "Inserire un nome valido",
      norm: "Normativa",
    },
    risks: "Rischi",
    signedAttachments: "Allegati firmati",
    sourceEmployees: "Lavoratori in scadenza da visitare",
    state: "Stato",
    states: {
      billed: "Fatturata",
      completed: "Eseguita",
      draft: "Bozza",
    },
    surveys: {
      addSurvey: "Aggiungi questionario",
      completedSurveyForm: "Completato",
      date: "Data",
      dateError: "Selezionare una data valida",
      form: "Scheda",
      formState: "Stato scheda",
      incompleteSurveyForm: "Da compilare",
      name: "Nome",
      stateError: "Lo stato deve essere completato",
      surveys: "Questionari",
      unexpectedSurveyForm: "Non previsto",
    },
    targetEmployees: "Lavoratori selezionati per visita medica",
    tests: {
      addTest: "Aggiungi accertamento",
      date: "Data",
      dateError: "Selezionare una data valida",
      file: "Allegato",
      fileError: "Caricare un documento",
      fitTestForm: "Regolare",
      form: "Scheda",
      formState: "Stato scheda",
      frequency: "Periodicità",
      incompleteTestForm: "Da compilare",
      name: "Nome",
      nameError: "Inserire un nome valido",
      state: "Stato",
      stateError: "Lo stato deve essere completato",
      tests: "Accertamenti",
      unexpectedTestForm: "Non previsto",
      unfitTestForm: "Alterato",
    },
    time: "Orario",
    timeError: "Inserire un orario valido",
    toExaminate: "Da visitare",
    type: "Tipo visita",
    types: {
      first: "Prima visita",
      periodic: "Visita periodica",
      extra: "Visita straordinaria",
    },
    typeError: "Selezionare un tipo di visita",
    uploadFile: "Carica allegato",
    widgets: {
      draftExaminationByCompany: "Visite mediche da effettuare per azienda",
      draftExaminationByDoctor: "Visite mediche da effettuare per medico",
      lastExaminationByCompany: "Visite mediche completate negli ultimi {days} giorni",
      lastExaminationByDoctor: "Visite mediche completate negli ultimi {days} giorni",
      todayExaminationByCompany: "Visite mediche di oggi",
      tomorrowExaminationByCompany: "Visite mediche di domani",
    },
    workHistories: {
      company: "Azienda",
      companyError: "Inserire l'azienda",
      duty: "Mansione",
      dutyError: "Inserire la mansione",
      period: "Periodo",
      periodError: "Inserire il periodo",
      sector: "Settore",
      sectorError: "Inserire il settore",
    },
    workHistory: "An. lav.",
    workHistoryLong: "Anamnesi lavorativa",
    year: "Anno",
  },
  navigation: {
    examination: "Visite mediche",
  },
  roleModules: {
    examination: "Visite mediche",
  },
  buttons: {
    calcExaminationItems: "Ricalcola rischi, accertamenti e questionari",
    deleteFile: "Elimina allegato",
    executeTest: "Esegui accertamento",
    fillForm: "Apri scheda",
    getLastExaminationJudgment: "Importa giudizio precedente",
    getOtherExamination: "Copia dati da altra visita",
    isPublicDisable: "Nascondi a azienda",
    isPublicEnable: "Mostra a azienda",
    nextExaminationOnFrequency: "Da periodicità",
    nextExamination1Month: "Fra 1 mese",
    nextExamination3Months: "Fra 3 mesi",
    nextExamination6Months: "Fra 6 mesi",
    nextExamination9Months: "Fra 9 mesi",
    nextExamination12Months: "Fra 12 mesi",
    openForm: "Apri",
    openHealthRecord: "Apri cartella sanitaria",
    openJudgmentForCompany: "Apri idoneità per datore di lavoro",
    openJudgmentForEmployee: "Apri idoneità per lavoratore",
    periodCurrentMonth: "Mese corrente",
    periodCurrentYear: "Anno corrente",
    periodDays: "Ultimi {days} giorni",
    periodLastMonth: "Mese scorso",
    periodLastYear: "Anno scorso",
    periodToday: "Oggi",
    periodTomorrow: "Domani",
    printAttachments: "Stampa allegati",
    printForm: "Stampa scheda",
    printHealthRecord: "Genera cartella sanitaria",
    printJudgmentForCompany: "Genera idoneità per datore di lavoro",
    printJudgmentForEmployee: "Genera idoneità per lavoratore",
    saveAndContinue: "Salva e continua",
    signHealthRecord: "Firma cartella sanitaria",
    signJudgmentForCompany: "Firma idoneità per datore di lavoro",
    signJudgmentForEmployee: "Firma idoneità per lavoratore",
    surveyCompleted: "Compilato",
    uploadFile: "Carica allegato",
    viewFile: "Visualizza allegato",
  },
  toast: {
    error: {
      createExamination: "Non è stato possibile creare la visita medica",
      createExaminationDraftExists: "Esiste già una visita medica in bozza",
      createExaminationEmployeeNoDuty: "Nessuna mansione disponibile per il lavoratore",
      createExaminationEmployeeNoEmployer: "Nessun contratto di lavoro disponibile per il lavoratore",
      createExaminationMissingData: "Seleziona i valori per effettuare una ricerca",
      createExaminationEmployeeMultipleDuties: "Il lavoratore ha più mansioni attive",
      createExaminationEmployeeMultipleEmployers: "Il lavoratore ha più contratti di lavoro attivi",
      createExaminationNoContract: "Non esiste alcun contratto attivo",
      createExaminationDoctorNotAvailable: "Il medico selezionato non è disponibile",
      createExaminationNoFirm: "Selezionare una ditta per proseguire",
      createExaminationPastDate: "La data della visita non può essere nel passato",
      toggleExaminationIsPublic: "Errore durante la modifica dello stato",
    },
    success: {
      createExamination: "Visite mediche create con successo",
      toggleExaminationIsPublic: "Stato modificato correttamente",
    },
    warning: {
      createExaminationNoEmployees: "Nessuna scadenza trovata",
      createExaminationEmployeesJustSome: "Alcune visite mediche non sono state create",
      noLastJudgment: "Nessun giudizio precedente trovato",
    },
  },
};
