import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { compareAsc } from "date-fns";

import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { FirestoreDocumentInterface } from "../interfaces/FirestoreDocument.interface";
import { User } from "@/core/modules/user/objects/User";

import { DateTimeStrictField, StringArrayField, StringStrictField } from "@/core/fields";
import { FirestoreDocumentRight } from "./FirestoreDocumentRight";

export class FirestoreDocument implements FirestoreDocumentInterface {
  public id: string;
  public parentId: string | undefined;
  public searchKeys: string[] = [];
  public createdAt: Date = new Date();
  public createdBy = "";
  public updatedAt: Date = new Date();
  public updatedBy = "";
  public rights = new FirestoreDocumentRight();

  public firestoreRef: firebase.firestore.DocumentReference | undefined = undefined;

  public constructor(id?: string) {
    const user: User = authStore.getter(authStoreTypes.getters.getUser);

    this.id = id ?? "new";
    this.createdBy = user?.id ?? "unknown";
    this.updatedBy = user?.id ?? "unknown";
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: firebase.firestore.DocumentReference): FirestoreDocument {
    this.id = id !== undefined ? id : StringStrictField.fromFirestore(data.id, "new");
    this.firestoreRef = firestoreRef;
    this.parentId = firestoreRef?.parent?.parent?.id;

    this.searchKeys = StringArrayField.fromFirestore(data.searchKeys);
    this.createdAt = DateTimeStrictField.fromFirestore(data.createdAt, new Date());
    this.createdBy = StringStrictField.fromFirestore(data.createdBy, "");
    this.updatedAt = DateTimeStrictField.fromFirestore(data.updatedAt, new Date());
    this.updatedBy = StringStrictField.fromFirestore(data.updatedBy, "");
    this.rights = new FirestoreDocumentRight(data.rights as Record<string, unknown> | undefined);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const data: Record<string, unknown> = {
      searchKeys: StringArrayField.toFirestore(this.searchKeys),
      createdAt: DateTimeStrictField.toFirestore(this.createdAt),
      createdBy: StringStrictField.toFirestore(this.createdBy),
      updatedAt: DateTimeStrictField.toFirestore(this.updatedAt),
      updatedBy: StringStrictField.toFirestore(this.updatedBy),
    };

    if (this.rights.hasRights()) data.rights = this.rights.toFirestore();

    return data;
  }

  public fromOfflineCache(data: Record<string, unknown>): FirestoreDocument {
    this.id = StringStrictField.fromOfflineCache(data.id, "new");
    this.firestoreRef = undefined;

    this.searchKeys = StringArrayField.fromOfflineCache(data.searchKeys);
    this.createdAt = DateTimeStrictField.fromOfflineCache(data.createdAt, new Date());
    this.createdBy = StringStrictField.fromOfflineCache(data.createdBy, "");
    this.updatedAt = DateTimeStrictField.fromOfflineCache(data.updatedAt, new Date());
    this.updatedBy = StringStrictField.fromOfflineCache(data.updatedBy, "");
    this.rights = new FirestoreDocumentRight(data.rights as Record<string, unknown> | undefined);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const data: Record<string, unknown> = {
      id: this.id,
      searchKeys: StringArrayField.toOfflineCache(this.searchKeys),
      createdAt: DateTimeStrictField.toOfflineCache(this.createdAt),
      createdBy: StringStrictField.toOfflineCache(this.createdBy),
      updatedAt: DateTimeStrictField.toOfflineCache(this.updatedAt),
      updatedBy: StringStrictField.toOfflineCache(this.updatedBy),
    };

    if (this.rights.hasRights()) data.rights = this.rights.toOfflineCache();

    return data;
  }

  public listenForChanges(callback: (firestoreDocument: FirestoreDocument) => void): () => void {
    if (this.firestoreRef === undefined) return () => null;

    return this.firestoreRef.onSnapshot((snapshot) => {
      if (snapshot.exists) {
        const firestoreDocument = this.fromFirestore(snapshot.data() as Record<string, unknown>, snapshot.id, snapshot.ref);
        callback(firestoreDocument);
      }
    });
  }

  public hasChangedFrom(oldFirestoreDocument: FirestoreDocument): boolean {
    return compareAsc(this.updatedAt, oldFirestoreDocument.updatedAt) != 0 || this.updatedBy != oldFirestoreDocument.updatedBy;
  }

  public setSearchKeys(): void {
    this.searchKeys = [];
  }

  public setTimestampFields(mode: "create" | "update"): void {
    const user: User = authStore.getter(authStoreTypes.getters.getUser);

    const now = new Date();
    if (mode == "create") {
      this.createdAt = now;
      this.createdBy = user.id ?? "unknown";
    }
    this.updatedAt = now;
    this.updatedBy = user.id ?? "unknown";
  }

  public userHasReadRight(userId: string): boolean {
    if (this.rights.read === undefined) return false;
    return this.rights.read.includes(userId);
  }

  public userHasUpdateRight(userId: string): boolean {
    if (this.rights.update === undefined) return false;
    return this.rights.update.includes(userId);
  }

  public userHasDeleteRight(userId: string): boolean {
    if (this.rights.delete === undefined) return false;
    return this.rights.delete.includes(userId);
  }

  public addReadRightForUser(userId: string): void {
    if (this.rights.read.includes(userId) === false) this.rights.read.push(userId);
  }

  public addUpdateRightForUser(userId: string): void {
    if (this.rights.update.includes(userId) === false) this.rights.update.push(userId);
  }

  public addDeleteRightForUser(userId: string): void {
    if (this.rights.delete.includes(userId) === false) this.rights.delete.push(userId);
  }

  public removeReadRightForUser(userId: string): void {
    const index = this.rights.read.indexOf(userId);
    if (index >= 0) this.rights.read.splice(index, 1);
  }

  public removeUpdateRightForUser(userId: string): void {
    const index = this.rights.update.indexOf(userId);
    if (index >= 0) this.rights.update.splice(index, 1);
  }

  public removeDeleteRightForUser(userId: string): void {
    const index = this.rights.delete.indexOf(userId);
    if (index >= 0) this.rights.delete.splice(index, 1);
  }
}
