import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedRole } from "@/core/modules/role/objects/LinkedRole";
import { UserDetail } from "./UserDetail";
import { UserInterface } from "../interfaces/User.interface";

import { BooleanField, DateTimeField, ObjectField, ObjectStrictField, StringField } from "@/core/fields";

export class User extends FirestoreDocument implements UserInterface {
  public firstName: string | undefined = undefined;
  public lastName: string | undefined = undefined;
  public fullName: string | undefined = undefined;
  public fullNameReversed: string | undefined = undefined;
  public email: string | undefined = undefined;
  public role: LinkedRole | undefined = undefined;
  public detail: UserDetail = new UserDetail();
  public blocked = false;
  public lastSeen: Date | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: firebase.firestore.DocumentReference): User {
    super.fromFirestore(data, id, firestoreRef);

    this.firstName = StringField.fromFirestore(data.firstName);
    this.lastName = StringField.fromFirestore(data.lastName);
    this.fullName = StringField.fromFirestore(data.fullName);
    this.fullNameReversed = StringField.fromFirestore(data.fullNameReversed);
    this.email = StringField.fromFirestore(data.email);
    this.role = ObjectField.fromFirestore<LinkedRole>(data.role, (value) => new LinkedRole(value));
    this.detail = ObjectStrictField.fromFirestore<UserDetail>(data.detail, (value) => new UserDetail(value));
    this.blocked = BooleanField.fromFirestore(data.blocked);
    this.lastSeen = DateTimeField.fromFirestore(data.lastSeen);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.firstName = StringField.toFirestore(this.firstName);
    firestoreData.lastName = StringField.toFirestore(this.lastName);
    firestoreData.fullName = StringField.toFirestore(this.fullName);
    firestoreData.fullNameReversed = StringField.toFirestore(this.fullNameReversed);
    firestoreData.email = StringField.toFirestore(this.email);
    firestoreData.role = ObjectField.toFirestore<LinkedRole>(this.role, (value) => value.toFirestore());
    firestoreData.detail = ObjectStrictField.toFirestore<UserDetail>(this.detail, (value) => value.toFirestore());
    firestoreData.blocked = BooleanField.toFirestore(this.blocked);
    firestoreData.lastSeen = DateTimeField.toFirestore(this.lastSeen);

    if (this.id === "new") firestoreData.sendMail = true;

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): User {
    super.fromOfflineCache(data);

    this.firstName = StringField.fromOfflineCache(data.firstName);
    this.lastName = StringField.fromOfflineCache(data.lastName);
    this.fullName = StringField.fromOfflineCache(data.fullName);
    this.fullNameReversed = StringField.fromOfflineCache(data.fullNameReversed);
    this.email = StringField.fromOfflineCache(data.email);
    this.role = ObjectField.fromOfflineCache<LinkedRole>(data.role, (value) => {
      const role: LinkedRole = new LinkedRole();
      return role.fromOfflineCache(value);
    });
    this.detail = ObjectStrictField.fromOfflineCache<UserDetail>(data.detail, (value) => {
      const detail: UserDetail = new UserDetail();
      return detail.fromOfflineCache(value);
    });
    this.blocked = BooleanField.fromOfflineCache(data.blocked);
    this.lastSeen = DateTimeField.fromOfflineCache(data.lastSeen);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.firstName = StringField.toOfflineCache(this.firstName);
    firestoreData.lastName = StringField.toOfflineCache(this.lastName);
    firestoreData.fullName = StringField.toOfflineCache(this.fullName);
    firestoreData.fullNameReversed = StringField.toOfflineCache(this.fullNameReversed);
    firestoreData.email = StringField.toOfflineCache(this.email);
    firestoreData.role = ObjectField.toOfflineCache<LinkedRole>(this.role, (value) => value.toOfflineCache());
    firestoreData.detail = ObjectStrictField.toOfflineCache<UserDetail>(this.detail, (value) => value.toOfflineCache());
    firestoreData.blocked = BooleanField.toOfflineCache(this.blocked);
    firestoreData.lastSeen = DateTimeField.toOfflineCache(this.lastSeen);

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = [];
    if (this.lastName != undefined) this.searchKeys.push(this.lastName.toLowerCase());
    if (this.firstName != undefined) this.searchKeys.push(this.firstName.toLowerCase());
    if (this.email != undefined) this.searchKeys.push(this.email.toLowerCase());
  }

  public setFullNames(): void {
    if (this.lastName != undefined && this.firstName != undefined) {
      this.fullName = `${this.lastName} ${this.firstName}`;
      this.fullNameReversed = `${this.firstName} ${this.lastName}`;
    } else {
      this.fullName = undefined;
      this.fullNameReversed = undefined;
    }
  }

  public getModuleRight(module: string, right: string): "all" | "owned" | "allowed" | undefined {
    if (module === undefined || right === undefined) return undefined;
    if (this.role === undefined) return undefined;

    if (module in this.role.roleRights === false) return undefined;

    if (right === "read") return this.role.roleRights[module].read;
    if (right === "update") return this.role.roleRights[module].update;
    if (right === "delete") return this.role.roleRights[module].delete;

    return undefined;
  }

  public hasCreateRight(module: string): boolean {
    if (this.role === undefined) return false;

    if (module in this.role.roleRights === false) return false;

    return this.role.roleRights[module].create;
  }

  public hasViewUIRight(module: string): boolean {
    if (this.role === undefined) return false;

    if (module in this.role.roleRights === false) return false;

    return this.role.roleRights[module].viewUI;
  }
}
