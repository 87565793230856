import { ArrayField } from "../array-field/ArrayField";

export class StringArrayField {
  public static fromFirestore(value: unknown): string[] {
    return ArrayField.fromFirestore(value, (item: unknown) => (item as string).trim());
  }

  public static toFirestore(value: string[]): unknown[] {
    return ArrayField.toFirestore(value, (item) => item.trim());
  }

  public static fromOfflineCache(value: unknown): string[] {
    return ArrayField.fromOfflineCache(value, (item: unknown) => (item as string).trim());
  }

  public static toOfflineCache(value: string[]): unknown[] {
    return ArrayField.toOfflineCache(value, (item) => item.trim());
  }
}
