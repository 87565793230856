
import { computed, defineComponent, onBeforeUnmount, onMounted } from "vue";
import { useRoute } from "vue-router";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { KoruEditorModule } from "../objects/KoruEditorModule";
import { koruEventBus } from "@/core/modules/event-bus";
import { koruStoreTypes } from "@/core/modules/store/types";
import { RowCommandInterface } from "@/core/modules/layout/interfaces/RowCommand.interface";
import { useKoruLocale } from "@/core/modules/locale";
import { useKoruStore } from "@/core/modules/store";

import { showCloseConfirmDialog, showToastWithWarningType, tryOrError, tryOrToast } from "@/core/modules/helpers";
import { userCanUpdate, userCanCreate, userCanDelete } from "@/core/modules/user/helpers";

export default defineComponent({
  name: "KoruEditor",
  props: {
    formSubmitted: { type: Boolean, default: false },
    isFormValid: { type: Boolean, default: false },
    module: { type: KoruEditorModule, default: undefined },
  },
  emits: ["update:formSubmitted", "update:module"],
  setup(props, { emit }) {
    const { t } = useKoruLocale();
    const koruStore = useKoruStore();

    const moduleRef = computed({
      get: () => props.module as KoruEditorModule<FirestoreDocument>,
      set: (val) => emit("update:module", val),
    });

    const route = useRoute();
    const requiredModule: string = route.meta.requiredModule as string;

    const formSubmittedRef = computed({
      get: () => props.formSubmitted,
      set: (val) => emit("update:formSubmitted", val),
    });

    const handleLoad = async () => {
      await tryOrError(
        async () => {
          await moduleRef.value.onMountedAction();
          await moduleRef.value.onLoadAction();
        },
        () => {
          koruStore.commit(koruStoreTypes.mutations.loadingStop);
          koruStore.commit(koruStoreTypes.mutations.editingStart);
        }
      );
    };

    const handleUnload = async () => {
      await tryOrError(async () => {
        await moduleRef.value.onUnloadAction();
      });
    };

    const handleSave = async (saveCommand?: () => void): Promise<void> => {
      formSubmittedRef.value = true;
      if (!props.isFormValid) {
        showToastWithWarningType("formInvalid", t);
        return;
      }
      koruStore.commit(koruStoreTypes.mutations.editingStop);
      koruStore.commit(koruStoreTypes.mutations.loadingStart);

      await tryOrToast(
        async () => {
          await moduleRef.value.onSubmitAction();
          if (saveCommand !== undefined) saveCommand();
        },
        "save",
        t,
        () => koruStore.commit(koruStoreTypes.mutations.loadingStop),
        false
      );
    };

    const handleSubmit = async (submitCommand?: () => void): Promise<void> => {
      formSubmittedRef.value = true;
      if (!props.isFormValid) {
        showToastWithWarningType("formInvalid", t);
        return;
      }
      koruStore.commit(koruStoreTypes.mutations.editingStop);
      koruStore.commit(koruStoreTypes.mutations.loadingStart);

      await tryOrToast(
        async () => {
          await moduleRef.value.onSubmitAction();

          if (moduleRef.value.afterSubmitAction !== undefined) {
            await (moduleRef.value.afterSubmitAction as () => Promise<void>)();
          } else {
            await koruStore.dispatch(koruStoreTypes.actions.navigateTo, koruStore.getters[koruStoreTypes.getters.getPreviousRoute]);
          }

          if (submitCommand !== undefined) submitCommand();
        },
        "save",
        t,
        () => koruStore.commit(koruStoreTypes.mutations.loadingStop)
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    koruEventBus.on("koruEditorSave", (saveCommand?: () => Promise<void>) => Promise.resolve(handleSave(saveCommand)));

    koruEventBus.on("koruEditorSubmit", (submitCommand?: () => Promise<void>) => Promise.resolve(handleSubmit(submitCommand)));

    const closeEditor = () => {
      koruStore.commit(koruStoreTypes.mutations.editingStop);
      koruStore.dispatch(koruStoreTypes.actions.navigateBack);
    };

    const getSaveCommands = () => {
      const renderSaveCommands: RowCommandInterface[] = [];

      if (moduleRef.value.saveCommands !== undefined && moduleRef.value.saveCommands.length > 0) {
        for (const saveCommand of moduleRef.value.saveCommands) {
          if (saveCommand.condition(moduleRef.value.document.value) === true) {
            renderSaveCommands.push(saveCommand);
          }
        }
      }

      return renderSaveCommands;
    };

    const confirmClose = (event: Event) => {
      showCloseConfirmDialog(
        event,
        () => {
          closeEditor();
        },
        t
      );
    };

    onMounted(() => {
      handleLoad();
    });

    onBeforeUnmount(() => {
      handleUnload();
    });

    return {
      closeEditor,
      confirmClose,
      getSaveCommands,
      handleKeydown,
      handleSubmit,
      koruStore,
      koruStoreTypes,
      moduleRef,
      requiredModule,
      t,
      userCanCreate,
      userCanDelete,
      userCanUpdate,
    };
  },
});
