import { uniqueId } from "@/core/plugins/unique-id";

import { KoruFileInterface } from "../interfaces/KoruFile.interface";

import { NumberField, StringField } from "@/core/fields";

export class KoruFile implements KoruFileInterface {
  public id: string = uniqueId();
  public uniqueName: string | undefined = undefined;
  public displayName: string | undefined = undefined;
  public type: string | undefined = undefined;
  public url: string | undefined = undefined;
  public size = 0;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): KoruFile {
    this.id = uniqueId();

    this.uniqueName = StringField.fromFirestore(data.uniqueName);
    this.displayName = StringField.fromFirestore(data.displayName);
    this.type = StringField.fromFirestore(data.type);
    this.url = StringField.fromFirestore(data.url);
    this.size = NumberField.fromFirestore(data.size);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.uniqueName = StringField.toFirestore(this.uniqueName);
    firestoreData.displayName = StringField.toFirestore(this.displayName);
    firestoreData.type = StringField.toFirestore(this.type);
    firestoreData.url = StringField.toFirestore(this.url);
    firestoreData.size = NumberField.toFirestore(this.size);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): KoruFile {
    this.id = uniqueId();

    this.uniqueName = StringField.fromOfflineCache(data.uniqueName);
    this.displayName = StringField.fromOfflineCache(data.displayName);
    this.type = StringField.fromOfflineCache(data.type);
    this.url = StringField.fromOfflineCache(data.url);
    this.size = NumberField.fromOfflineCache(data.size);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.uniqueName = StringField.toOfflineCache(this.uniqueName);
    firestoreData.displayName = StringField.toOfflineCache(this.displayName);
    firestoreData.type = StringField.toOfflineCache(this.type);
    firestoreData.url = StringField.toOfflineCache(this.url);
    firestoreData.size = NumberField.toOfflineCache(this.size);

    return firestoreData;
  }
}
