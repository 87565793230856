export const fr = {
  feedback: {
    feedback: "Commentaires",
    message: "Message",
    messageError: "Veuillez saisir votre message",
    subject: "Sujet",
    subjectError: "Veuillez entrer le sujet",
  },
  navigation: {
    feedback: "Commentaires",
  },
  roleModules: {
    feedback: "Commentaires",
  },
};
