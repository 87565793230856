export const frPrimevue = {
  startsWith: "Commence par",
  contains: "Contient",
  notContains: "Ne contient pas",
  endsWith: "Se termine par",
  equals: "Égal",
  notEquals: "Pas égal",
  noFilter: "Aucun filtre",
  lt: "Moins de",
  lte: "Inférieur ou égal à",
  gt: "Supérieur à",
  gte: "Supérieur ou égal à",
  dateIs: "La date est",
  dateIsNot: "La date n'est pas",
  dateBefore: "La date est antérieure",
  dateAfter: "La date est postérieure",
  clear: "Effacer",
  apply: "Appliquer",
  matchAll: "Tout correspondre",
  matchAny: "Correspond à n'importe quel",
  addRule: "Ajouter une règle",
  removeRule: "Supprimer la règle",
  accept: "Oui",
  reject: "Non",
  choose: "Choisir",
  upload: "Télécharger",
  cancel: "Annuler",
  dayNames: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
  dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  dayNamesMin: ["Di", "Mo", "Tu", "Me", "Je", "Ven", "Sa"],
  monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  today: "Aujourd'hui",
  weekHeader: "Semaine.",
  firstDayOfWeek: 1,
  dateFormat: "dd/mm/yy",
  weak: "Faible",
  medium: "Moyen",
  strong: "Fort",
  passwordPrompt: "Entrez un mot de passe",
  emptyFilterMessage: "Aucun résultat trouvé",
  emptyMessage: "Aucune option disponible",
};
