import firebase from "firebase/compat/app";
import "firebase/compat/storage";

import { KoruFile } from "@/core/modules/file/objects/KoruFile";
import { storageModel } from "../Storage.model";

import { uniqueId } from "@/core/plugins/unique-id";

export const uploadFile = async (path: string, file: File, name: string | undefined): Promise<KoruFile> => {
  try {
    if (path === undefined) throw new Error("undefinedPath");
    if (file === undefined) throw new Error("undefinedFile");

    const extension = file.name.split(".").pop();

    let uniqueName = "";
    let exists = true;
    let suffix = 0;

    const fileName: string = name !== undefined ? name : uniqueId();

    while (exists) {
      uniqueName = suffix > 0 ? `${fileName}_${suffix}.${extension}` : `${fileName}.${extension}`;
      exists = await storageModel.fileExists(path, uniqueName);
      suffix++;
    }

    await storageModel.upload(path, uniqueName, file, (progress) => {
      console.log(progress);
    });

    const koruFile: KoruFile = new KoruFile();
    koruFile.uniqueName = uniqueName;
    koruFile.displayName = file.name;
    koruFile.type = extension?.toUpperCase() || "";
    koruFile.url = await firebase.storage().ref(path).child(uniqueName).getDownloadURL();
    koruFile.size = file.size;

    return koruFile;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
