import { koruApp } from "@/main";

export function showCloseConfirmDialog(event: Event, confirmAction: () => void, t?: (entry: string) => string): void {
  const confirm = koruApp.config.globalProperties.$confirm;

  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t != undefined ? t("dialog.confirmCloseWithoutSave") : "dialog.confirmCloseWithoutSave",
    icon: "pi pi-exclamation-circle",
    accept: () => {
      confirmAction();
    },
    acceptIcon: "pi pi-check",
    acceptLabel: t != undefined ? t("gen.yes") : "yes",
    rejectIcon: "pi pi-times",
    rejectLabel: t != undefined ? t("gen.no") : "no",
  });
}

export function showDeleteConfirmDialog(event: Event, confirmAction: () => void, t?: (entry: string) => string): void {
  const confirm = koruApp.config.globalProperties.$confirm;

  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t != undefined ? t("dialog.confirmDelete") : "dialog.confirmDelete",
    icon: "pi pi-times-circle",
    accept: () => {
      confirmAction();
    },
    acceptClass: "p-button-danger",
    acceptIcon: "pi pi-check",
    acceptLabel: t != undefined ? t("gen.yes") : "yes",
    rejectIcon: "pi pi-times",
    rejectLabel: t != undefined ? t("gen.no") : "no",
  });
}
