import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { User } from "../objects/User";

export function userHasRoleByName(roleName: string): boolean {
  const user: User = authStore.getter(authStoreTypes.getters.getUser);
  if (user == undefined) return false;
  if (user.role == undefined) return false;
  return user.role.name == roleName;
}

export function userCanRead(module: string, firestoreDocument?: FirestoreDocument): boolean {
  const user: User = authStore.getter(authStoreTypes.getters.getUser);
  if (user === undefined || user.role === undefined) return false;

  if (module in user.role.roleRights === false) return false;

  if (user.role.roleRights[module].read === "all") return true;

  if (firestoreDocument === undefined) return false;
  if (user.role.roleRights[module].read === "owned" && firestoreDocument.createdBy == user.id) return true;

  if (user.role.roleRights[module].read === "allowed" && firestoreDocument.userHasReadRight(user.id)) return true;

  return false;
}

export function userCanReadAll(module: string): boolean {
  const user: User = authStore.getter(authStoreTypes.getters.getUser);
  if (user === undefined || user.role === undefined) return false;

  if (module in user.role.roleRights === false) return false;

  return user.role.roleRights[module].read === "all";
}

export function userCanReadOwned(module: string): boolean {
  const user: User = authStore.getter(authStoreTypes.getters.getUser);
  if (user === undefined || user.role === undefined) return false;

  if (module in user.role.roleRights === false) return false;

  return user.role.roleRights[module].read === "owned";
}

export function userCanCreate(module: string): boolean {
  const user: User = authStore.getter(authStoreTypes.getters.getUser);
  if (user === undefined || user.role === undefined) return false;

  if (module in user.role.roleRights === false) return false;

  return user.role.roleRights[module].create;
}

export function userCanUpdate(module: string, firestoreDocument?: FirestoreDocument): boolean {
  const user: User = authStore.getter(authStoreTypes.getters.getUser);
  if (user === undefined || user.role === undefined) return false;

  if (module in user.role.roleRights === false) return false;

  if (user.role.roleRights[module].update === "all") return true;

  if (firestoreDocument === undefined) return false;
  if (user.role.roleRights[module].update === "owned" && firestoreDocument.createdBy == user.id) return true;

  if (user.role.roleRights[module].update === "allowed" && firestoreDocument.userHasUpdateRight(user.id)) return true;

  return false;
}

export function userCanDelete(module: string, firestoreDocument?: FirestoreDocument): boolean {
  const user: User = authStore.getter(authStoreTypes.getters.getUser);
  if (user === undefined || user.role === undefined) return false;

  if (module in user.role.roleRights === false) return false;

  if (user.role.roleRights[module].delete === "all") return true;

  if (firestoreDocument === undefined) return false;
  if (user.role.roleRights[module].delete === "owned" && firestoreDocument.createdBy == user.id) return true;

  if (user.role.roleRights[module].delete === "allowed" && firestoreDocument.userHasDeleteRight(user.id)) return true;

  return false;
}

export function userCanViewUI(module: string): boolean {
  const user: User = authStore.getter(authStoreTypes.getters.getUser);
  if (user === undefined || user.role === undefined) return false;

  if (module in user.role.roleRights === false) return false;

  return user.role.roleRights[module].viewUI;
}

export function userHasAnyViewUIRight(user: User, modules: string[]): boolean {
  if (modules.length === 0) return true;

  if (user === undefined || user.role === undefined) return false;

  for (const module of modules) {
    if (module in user.role.roleRights === false) continue;

    if (user.role.roleRights[module].viewUI === true) return true;
  }
  return false;
}
