import firebase from "firebase/compat/app";
import "firebase/compat/functions";

import { koruConfig } from "@/core/modules/config";

export async function runBeforeDeleteFunction(functionName: string, snapshotId: string): Promise<void> {
  const beforeDeleteFunction: firebase.functions.HttpsCallable = firebase.app().functions(koruConfig.firebase.region).httpsCallable(functionName);
  const functionResult: firebase.functions.HttpsCallableResult = await beforeDeleteFunction({ snapshotId: snapshotId });
  const response: { result: string; where?: string } = functionResult.data;

  if (response == undefined || response.result == undefined) throw new Error("actionError");
  if (response.result == "children") throw new Error("children");
  if (response.result != "ok") throw new Error("actionError");

  return Promise.resolve();
}

export async function runCreateUserFunction(functionName: string, snapshotId: string): Promise<boolean> {
  const createUserFunction: firebase.functions.HttpsCallable = firebase.app().functions(koruConfig.firebase.region).httpsCallable(functionName);
  const functionResult: firebase.functions.HttpsCallableResult = await createUserFunction({ snapshotId: snapshotId });
  const response: { result: string } = functionResult.data;

  if (response === undefined || response.result === undefined) return Promise.resolve(false);
  if (response.result === "error") return Promise.resolve(false);
  if (response.result !== "ok") return Promise.resolve(false);

  return Promise.resolve(true);
}

export async function runGenericFunction(functionName: string, data: Record<string, unknown>): Promise<Record<string, unknown> | undefined> {
  const genericFunction: firebase.functions.HttpsCallable = firebase.app().functions(koruConfig.firebase.region).httpsCallable(functionName);
  const functionResult: firebase.functions.HttpsCallableResult = await genericFunction(data);
  const response: Record<string, unknown> = functionResult.data;

  if (response === undefined || response.result === undefined) return Promise.resolve(undefined);

  return Promise.resolve(response.result as Record<string, unknown>);
}

export async function runValidatorFunction(functionName: string, value: unknown, snapshotId: string): Promise<boolean> {
  const validatorFunction: firebase.functions.HttpsCallable = firebase.app().functions(koruConfig.firebase.region).httpsCallable(functionName);
  const functionResult: firebase.functions.HttpsCallableResult = await validatorFunction({ value: value, snapshotId: snapshotId });
  const response: { result: string; where?: string } = functionResult.data;

  if (response === undefined || response.result === undefined) return Promise.resolve(false);
  if (response.result === "error") return Promise.resolve(false);
  if (response.result !== "ok") return Promise.resolve(false);

  return Promise.resolve(true);
}
