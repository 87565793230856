import { Ref, ref } from "vue";

import { KoruTreeModuleInterface } from "../interfaces/KoruTreeModule.interface";
import { RowCommandInterface } from "@/core/modules/layout/interfaces/RowCommand.interface";
import { TreeNode } from "primevue/tree";

export class KoruTreeModule<T> implements KoruTreeModuleInterface<T> {
  public canCreate = true;
  public canDelete = true;
  public canEdit = true;
  public deleteDescriptionField = "";
  public documents: Ref<T[]> = ref([]);
  public expandedKeys: Ref<Record<string, unknown>> = ref({});
  public explicitRequiredModule: string | undefined = undefined;
  public loadingStop = true;
  public objectName = "";
  public rowCommands: RowCommandInterface[] = [];
  public suffix = "";

  public computeNodes(): TreeNode[] {
    return [];
  }

  public onCreateAction(parent: unknown | undefined): Promise<void> {
    console.log(parent);
    return Promise.resolve();
  }

  public onDeleteAction(document: T): Promise<void> {
    console.log(document);
    return Promise.resolve();
  }

  public onLoadAction(): Promise<void> {
    return Promise.resolve();
  }

  public onMountedAction(): Promise<void> {
    return Promise.resolve();
  }

  public onUpdateAction(document: T): Promise<void> {
    console.log(document);
    return Promise.resolve();
  }
}
