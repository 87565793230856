
import { computed, defineComponent, onMounted, Ref, ref, WritableComputedRef } from "vue";

import * as _ from "lodash";
import { FileUploadSelectEvent } from "primevue/fileupload";

import { koruEventBus } from "@/core/modules/event-bus";
import { KoruFile } from "@/core/modules/file/objects/KoruFile";
import { storageModel } from "@/core/modules/storage/models/Storage.model";
import { useKoruConfig } from "@/core/modules/config";
import { useKoruLocale } from "@/core/modules/locale";

import { formatFileSize, showToastWithErrorType, tryOrToast } from "@/core/modules/helpers";

export default defineComponent({
  name: "KoruImageUpload",
  props: {
    accept: { type: String, default: undefined },
    canDelete: { type: Boolean, default: true },
    file: { type: KoruFile, default: undefined },
    maxFileSize: { type: Number, default: undefined },
    path: { type: String, default: undefined },
  },
  emits: ["update:file"],
  setup(props, { emit }) {
    const { t } = useKoruLocale();

    const koruConfig = useKoruConfig();
    const placeholderImg = "/assets/core/images/placeholder.png";

    const fileRef: WritableComputedRef<KoruFile | undefined> = computed({
      get: () => props.file as KoruFile | undefined,
      set: (val) => emit("update:file", val),
    });

    const fileToDelete: Ref<KoruFile | undefined> = ref(undefined);
    const fileToUpload: Ref<File | undefined> = ref(undefined);
    const filePreview: Ref<string | undefined> = ref(undefined);

    const submitAction = async (newName: string | undefined): Promise<void> => {
      if (props.path === undefined) return;

      if (fileToDelete.value !== undefined && props.canDelete === true && fileToDelete.value.uniqueName !== undefined) {
        await storageModel.deleteFile(props.path, fileToDelete.value.uniqueName);
      }
      if (fileToUpload.value !== undefined) {
        fileRef.value = await storageModel.uploadFile(props.path, fileToUpload.value, newName);
      }
    };

    const onFileSelected = async (event: FileUploadSelectEvent): Promise<void> => {
      await tryOrToast(
        async () => {
          if (event.files.length > 0) {
            fileToUpload.value = event.files[0];

            const reader: FileReader = new FileReader();
            reader.onload = function (e) {
              filePreview.value = e.target?.result as string;
            };
            reader.readAsDataURL(event.files[0]);
          } else {
            fileToUpload.value = undefined;
          }
        },
        "uploadFile",
        t,
        undefined,
        false
      );
    };

    const openFile = async () => {
      try {
        if (fileRef.value === undefined) return;
        if (fileRef.value.url === undefined) return;

        window.open(fileRef.value.url, "_blank");
      } catch (error) {
        showToastWithErrorType("readFile", "action", t);
      }
    };

    const deleteFile = () => {
      if (props.canDelete === false) return;
      fileToDelete.value = _.cloneDeep(fileRef.value);
      fileRef.value = undefined;
    };

    onMounted(() => {
      koruEventBus.on("koruImageUploadSubmit", submitAction);
    });

    return {
      deleteFile,
      filePreview,
      fileRef,
      formatFileSize,
      koruConfig,
      onFileSelected,
      openFile,
      placeholderImg,
      t,
    };
  },
});
