
import { defineComponent } from "vue";
import { Loader } from "@googlemaps/js-api-loader";

import { uniqueId } from "@/core/plugins/unique-id";
import { useKoruConfig } from "@/core/modules/config";

export default defineComponent({
  name: "KoruMap",
  props: {
    height: { type: Number, default: 600 },
    initFunction: { type: Function, default: undefined },
  },
  setup(props) {
    const koruConfig = useKoruConfig();

    const elementId = "map-" + uniqueId();

    // TODO with config?
    const mapCenter: google.maps.LatLngLiteral = {
      lat: 0,
      lng: 0,
    };
    const mapZoom = 12;

    const loader = new Loader({
      apiKey: koruConfig.api.googleMaps.key,
      version: "weekly",
    });

    loader.load().then(() => {
      const mapDiv: HTMLElement = document.getElementById(elementId) as HTMLElement;
      const map: google.maps.Map = new window.google.maps.Map(mapDiv, {
        center: mapCenter,
        zoom: mapZoom,
        streetViewControl: false,
      });
      if (props.initFunction != undefined) {
        props.initFunction(map);
      }
    });

    return {
      elementId,
    };
  },
});
