import { Ref, ref } from "vue";

import { KoruSortableBrowserModuleInterface } from "../interfaces/KoruSortableBrowserModule.interface";
import { RowCommandInterface } from "@/core/modules/layout/interfaces/RowCommand.interface";

export class KoruSortableBrowserModule<T> implements KoruSortableBrowserModuleInterface<T> {
  public canCreate = true;
  public canDelete = true;
  public canEdit = true;
  public canView = true;
  public createPath: string | undefined = undefined;
  public deleteDescriptionField = "";
  public documents: Ref<T[]> = ref([]);
  public explicitRequiredModule: string | undefined = undefined;
  public loadingStop = true;
  public objectName = "";
  public path = "";
  public rowCommands: RowCommandInterface[] = [];
  public suffix = "";

  public onDeleteAction(document: T): Promise<void> {
    console.log(document);
    return Promise.resolve();
  }

  public onLoadAction(): Promise<void> {
    return Promise.resolve();
  }

  public onLoadWithFiltersAction(): Promise<void> {
    return Promise.resolve();
  }

  public onMountedAction(): Promise<void> {
    return Promise.resolve();
  }

  public onReorderAction(documents: T[]): Promise<void> {
    console.log(documents);
    return Promise.resolve();
  }
}
