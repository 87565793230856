import { ActionTree, GetterTree, MutationTree } from "vuex";

import { commitModuleMutation, dispatchModuleAction, retrieveModuleGetter, useKoruStore } from "@/core/modules/store";
import { KoruStateInterface } from "../interfaces/KoruState.interface";
import { KoruStoreModuleInterface } from "../interfaces/KoruStoreModule.interface";

export function createStoreModule<T>(
  namespace: string,
  initialState?: T,
  getters?: GetterTree<T, KoruStateInterface>,
  actions?: ActionTree<T, KoruStateInterface>,
  mutations?: MutationTree<T>
): KoruStoreModuleInterface<T> {
  return {
    namespaced: true,
    namespace: namespace,
    get state(): T {
      return initialState || ({ empty: "empty" } as unknown as T);
    },
    getters: getters,
    actions: actions,
    mutations: mutations,
    getter<T>(getterName: string): T {
      return retrieveModuleGetter<T>(namespace, getterName);
    },
    action<T>(actionName: string, params?: T): Promise<unknown> {
      return dispatchModuleAction(namespace, actionName, params);
    },
    commit<T>(mutationName: string, params?: T): void {
      commitModuleMutation(namespace, mutationName, params);
    },
  };
}

export function registerStoreModule<T>(storeModule: KoruStoreModuleInterface<T>): void {
  const koruStore = useKoruStore();
  if (!koruStore.hasModule(storeModule.namespace)) {
    koruStore.registerModule(storeModule.namespace, storeModule);
  }
}
