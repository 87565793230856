
import { defineComponent } from "vue";

import { NavigationItemInterface } from "@/core/modules/navigation/interfaces/NavigationItem.interface";
import { useKoruConfig } from "@/core/modules/config";
import { useKoruLocale } from "@/core/modules/locale";

import SubMenu from "./SubMenu.vue";

export default defineComponent({
  name: "Menu",
  components: {
    SubMenu,
  },
  props: {
    model: Array<NavigationItemInterface>,
    layoutMode: String,
    active: Boolean,
    mobileMenuActive: Boolean,
  },
  emits: ["menu-click", "menuitem-click", "root-menuitem-click"],
  setup() {
    const { t } = useKoruLocale();
    const koruConfig = useKoruConfig();

    const sidebarLogoUrl: string =
      koruConfig.app.logos.sidebarImage === "features" ? "/assets/features/images/logo-white.svg" : "/assets/core/images/koru-logo-white.svg";

    return {
      koruConfig,
      sidebarLogoUrl,
      t,
    };
  },
  methods: {
    onMenuClick(event: Event): void {
      this.$emit("menu-click", event);
    },
    onMenuItemClick(event: Event): void {
      this.$emit("menuitem-click", event);
    },
    onRootMenuItemClick(event: Event): void {
      this.$emit("root-menuitem-click", event);
    },
  },
});
