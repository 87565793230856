
import { computed, defineComponent, Ref } from "vue";

import { koruStoreTypes } from "@/core/modules/store/types";
import { useKoruLocale } from "@/core/modules/locale";
import { useKoruStore } from "@/core/modules/store";

export default defineComponent({
  name: "KoruBreadcrumb",
  props: {
    editRoute: { type: String, default: undefined },
    items: { type: Object, default: undefined },
    type: { type: String, default: "full" },
  },
  setup(props) {
    const { t } = useKoruLocale();
    const koruStore = useKoruStore();

    const classes: Record<string, boolean> = {
      card: true,
      "w-full": true,
      "xl:w-8": props.type == "boxed",
    };

    const home: Record<string, unknown> = {
      icon: "pi pi-home",
      to: "/",
    };

    const itemsRef: Ref<Record<string, unknown>[]> = computed(() => props.items as Record<string, unknown>[]);

    const goToEdit = () => {
      koruStore.dispatch(koruStoreTypes.actions.navigateTo, props.editRoute);
    };

    return {
      classes,
      goToEdit,
      home,
      itemsRef,
      t,
    };
  },
});
