
import { defineComponent, onMounted } from "vue";

import { koruStoreTypes } from "@/core/modules/store/types";
import { useKoruStore } from "@/core/modules/store";

import { tryOrError } from "@/core/modules/helpers";

export default defineComponent({
  name: "KoruViewer",
  props: {
    breadcrumbs: { type: Object, default: undefined },
    editRoute: { type: String },
    loadAction: { type: Function },
  },
  emits: ["update:sectionIndex"],
  setup(props) {
    const koruStore = useKoruStore();

    const handleLoad = async () => {
      await tryOrError(
        async () => {
          if (props.loadAction != undefined) await props.loadAction();
        },
        () => koruStore.commit(koruStoreTypes.mutations.loadingStop)
      );
    };

    onMounted(() => handleLoad());

    return {};
  },
});
