import { Ref, ref } from "vue";

import { KoruEditorModuleInterface } from "../interfaces/KoruEditorModule.interface";
import { RowCommandInterface } from "@/core/modules/layout/interfaces/RowCommand.interface";

export class KoruEditorModule<T> implements KoruEditorModuleInterface<T> {
  public document: Ref<T>;

  public constructor(newIstance: T) {
    this.document = ref(newIstance) as Ref<T>;
  }

  public breadcrumbs: Ref<Record<string, unknown>[]> = ref([]);
  public canCreate = true;
  public canEdit = true;
  public rules: Record<string, unknown> = {};
  public saveCommands: RowCommandInterface[] = [];
  public submitCommands: RowCommandInterface[] = [];

  public afterSubmitAction = undefined;

  public onLoadAction(): Promise<void> {
    return Promise.resolve();
  }

  public onMountedAction(): Promise<void> {
    return Promise.resolve();
  }

  public onSubmitAction(): Promise<void> {
    return Promise.resolve();
  }

  public onUnloadAction(): Promise<void> {
    return Promise.resolve();
  }
}
