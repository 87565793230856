import { LinkedFirestoreDocumentInterface } from "../interfaces/LinkedFirestoreDocument.interface";

export class LinkedFirestoreDocument implements LinkedFirestoreDocumentInterface {
  public id: string;

  public constructor(id?: string) {
    this.id = id ?? "new";
  }

  public fromFirestore(data: Record<string, unknown>): LinkedFirestoreDocument {
    this.id = (data.id as string) ?? undefined;
    return this;
  }

  public toFirestore(): Record<string, unknown> {
    return {
      id: this.id ?? null,
    };
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedFirestoreDocument {
    this.id = (data.id as string) ?? undefined;
    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    return {
      id: this.id ?? null,
    };
  }
}
