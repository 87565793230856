import { registerLocaleModule } from "@/core/modules/locale/helpers";

import { feedbackLocaleModule } from "@/core/modules/feedback/locales";
import { firestoreLocaleModule } from "@/core/modules/firestore/locales";
import { userLocaleModule } from "@/core/modules/user/locales";
import { whoLocaleModule } from "@/core/modules/who/locales";

export const registerCoreLocales = () => {
  registerLocaleModule(feedbackLocaleModule);
  registerLocaleModule(firestoreLocaleModule);
  registerLocaleModule(userLocaleModule);
  registerLocaleModule(whoLocaleModule);
};
