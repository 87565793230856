import { FirestoreSorter } from "../firestore/objects/FirestoreSorter";
import { SortCriteria } from "../firestore/objects/SortCriteria";

export function arrayContainsArray(superset: unknown[], subset: unknown[]): boolean {
  if (subset.length === 0) return false;
  return subset.every((value) => superset.includes(value));
}

export async function base64StringToPdf(base64String: string, fileName: string): Promise<File> {
  const blob: Blob = await (await fetch(`data:application/pdf;base64,${base64String}`, { headers: { "Content-Type": "application/pdf" } })).blob();
  return new File([blob], fileName, { type: "application/pdf" });
}

export function blobToBase64(blob: Blob): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result === null) {
        reject(undefined);
      } else {
        const result: string = reader.result as string;
        resolve(result.slice(result.indexOf(",") + 1));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export function getDayOfISOWeek(week: number, year: number, dayOfWeek: number): Date {
  const date: Date = getStartDateOfISOWeek(week, year);
  date.setDate(date.getDate() + (+dayOfWeek || 7) - 1);
  return date;
}

export function getStartDateOfISOWeek(week: number, year: number): Date {
  // Get date for 1 Jan in given year
  const date: Date = new Date(year, 0, 1);
  const dayOfWeek: number = date.getDay();
  // Shift to start of ISO week 1
  date.setDate((dayOfWeek <= 4 ? 2 : 9) - date.getDay());
  // Add required number of weeks
  date.setDate(date.getDate() + (week - 1) * 7);

  return date;
}

export function objectToOrderedArray<T>(object: Record<string, T>, sortCriteria = new SortCriteria("order", "asc", "number")): T[] {
  const sorter: FirestoreSorter<T> = new FirestoreSorter(Object.values(object));
  sorter.setSortCriterias([sortCriteria]);
  return sorter.sort();
}

export function orderedArrayToObject<T>(orderedArray: T[]): Record<string, T> {
  const object: Record<string, T> = {};
  for (const item of orderedArray) {
    object[item["id" as keyof T] as string] = item;
  }
  return object;
}

export function mmToPoints(mm: number): number {
  const MILLIMETERS_IN_INCH = 25.4;
  const POINTS_IN_INCH = 72;

  const inches = mm / MILLIMETERS_IN_INCH;
  return inches * POINTS_IN_INCH;
}

export function normalizeString(string: string): string {
  return string
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-z0-9]/gi, "");
}

export function randomIntBetween(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function randomElementFromArray<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

export function roundNumber(number: number, digits: number): number {
  const pow = Math.pow(10, digits);
  return Math.round((number + Number.EPSILON) * pow) / pow;
}

export function slugify(...args: (string | number)[]): string {
  const value = args.join(" ");
  return value
    .normalize("NFD") // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, "-"); // separator
}
