import firebase from "firebase/compat/app";
import "firebase/compat/storage";

export const upload = async (path: string, name: string, file: File, callback: (progress: number) => void): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const metaData: Record<string, string> = {};
    if (name.endsWith(".doc")) metaData.contentType = "application/msword";
    if (name.endsWith(".docx")) metaData.contentType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    if (name.endsWith(".gif")) metaData.contentType = "image/gif";
    if (name.endsWith(".jpeg")) metaData.contentType = "image/jpeg";
    if (name.endsWith(".jpg")) metaData.contentType = "image/jpeg";
    if (name.endsWith(".odt")) metaData.contentType = "application/vnd.oasis.opendocument.text";
    if (name.endsWith(".pdf")) metaData.contentType = "application/pdf";
    if (name.endsWith(".png")) metaData.contentType = "image/png";

    const uploadTask: firebase.storage.UploadTask = firebase.storage().ref(path).child(name).put(file, metaData);

    uploadTask.on(
      "state_changed",
      (snapshot: firebase.storage.UploadTaskSnapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        callback(Math.floor(progress));
      },
      (error: firebase.storage.FirebaseStorageError) => {
        console.log(error);
        reject();
      },
      () => {
        resolve();
      }
    );
  });
};
