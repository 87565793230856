import { createRouter, createWebHistory, Router } from "vue-router";

import { authStoreTypes } from "@/core/modules/auth/store/types";
import { koruConfig } from "../config";
import { koruStoreTypes } from "@/core/modules/store/types";
import { routes } from "./routes";
import { useAuthStore } from "@/core/modules/auth/store";
import { useKoruStore } from "@/core/modules/store";
import { User } from "@/core/modules/user/objects/User";

export const koruRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

koruRouter.beforeEach((to, from, next) => {
  const koruStore = useKoruStore();
  const koruAuth = useAuthStore();

  if (to.path === "/maintenance") {
    if (koruConfig.app.maintenance === false) next("/");
  } else {
    if (koruConfig.app.maintenance === true) {
      if (koruAuth.getter(authStoreTypes.getters.isLoggedIn)) {
        const user: User = koruAuth.getter(authStoreTypes.getters.getUser);
        if (user.role?.name !== "Master") next("/maintenance");
      } else {
        next("/maintenance");
      }
    }
  }

  window.scrollTo(0, 0);

  koruStore.commit(koruStoreTypes.mutations.setPreviousRoute, from);

  if (to.name == "Error" || to.name == "NotFound" || to.name == "AccessDenied") {
    koruStore.commit(koruStoreTypes.mutations.editingStop);
  }

  if (koruStore.getters[koruStoreTypes.getters.isEditing]) {
    koruStore.commit(koruStoreTypes.mutations.openEditingDialog, to);
    return;
  }

  if (from.path != to.path) {
    koruStore.commit(koruStoreTypes.mutations.loadingStart);
  }

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    if (koruAuth.getter(authStoreTypes.getters.isLoggedIn) && to.path == "/login") {
      next("/");
    } else if (koruAuth.getter(authStoreTypes.getters.isLoggedIn)) {
      const user: User = koruAuth.getter(authStoreTypes.getters.getUser);
      const requiredModule: string = to.meta.requiredModule as string;
      const requiredRight: string = to.meta.requiredRight as string;

      if (requiredModule === "" || requiredRight === "") {
        next();
      } else if (requiredRight === "viewUI" && user.hasViewUIRight(requiredModule)) {
        next();
      } else if (requiredRight === "create" && user.hasCreateRight(requiredModule)) {
        next();
      } else if (user.getModuleRight(requiredModule, requiredRight) !== undefined) {
        next();
      } else {
        next("/access-denied");
      }
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export const useKoruRouter = (): Router => {
  return koruRouter;
};
