import { RouteRecordRaw } from "vue-router";

export const examinationRoutes: Array<RouteRecordRaw> = [
  {
    path: "/examinations",
    name: "ExaminationList",
    component: () => import("../views/ExaminationList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "",
      requiredRight: "",
    },
  },
];
