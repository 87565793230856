import type { DateTimeFormat, LocaleMessage, NumberFormat } from "@intlify/core-base";
import { createI18n, useI18n, VueMessageType } from "vue-i18n";

import { koruConfig } from "@/core/modules/config";

import { coreLabels } from "@/core/locales/labels";
import { featuresLabels } from "@/features/locales/labels";

import { allCoreDateTimeFormats, allCoreNumberFormats } from "@/core/locales/formats";
import { allFeaturesDateTimeFormats, allFeaturesNumberFormats } from "@/features/locales/formats";
import { LocaleModuleInterface } from "./interfaces/LocaleModule.interface";

export const koruLocaleModules: LocaleModuleInterface[] = [];

export const initKoruLocale = () => {
  const labels: { [x: string]: LocaleMessage<VueMessageType> } = {};
  const dateTimeFormats: { [x: string]: DateTimeFormat } = {};
  const numberFormats: { [x: string]: NumberFormat } = {};

  for (const locale of koruConfig.app.locale.locales) {
    labels[locale.name] = { ...coreLabels.messages[locale.name], ...featuresLabels.messages[locale.name] };
    labels[locale.name].buttons = {
      ...(coreLabels.messages[locale.name].buttons as Record<string, unknown>),
      ...(featuresLabels.messages[locale.name].buttons as Record<string, unknown>),
    };
    labels[locale.name].gen = {
      ...(coreLabels.messages[locale.name].gen as Record<string, unknown>),
      ...(featuresLabels.messages[locale.name].gen as Record<string, unknown>),
    };
    labels[locale.name].navigation = {
      ...(coreLabels.messages[locale.name].navigation as Record<string, unknown>),
      ...(featuresLabels.messages[locale.name].navigation as Record<string, unknown>),
    };
    labels[locale.name].roleModules = {
      ...(coreLabels.messages[locale.name].roleModules as Record<string, unknown>),
      ...(featuresLabels.messages[locale.name].roleModules as Record<string, unknown>),
    };
    labels[locale.name].toast = {
      error: {
        ...((coreLabels.messages[locale.name].toast as Record<string, unknown>).error as Record<string, unknown>),
        ...((featuresLabels.messages[locale.name].toast as Record<string, unknown>).error as Record<string, unknown>),
      },
      success: {
        ...((coreLabels.messages[locale.name].toast as Record<string, unknown>).success as Record<string, unknown>),
        ...((featuresLabels.messages[locale.name].toast as Record<string, unknown>).success as Record<string, unknown>),
      },
      warning: {
        ...((coreLabels.messages[locale.name].toast as Record<string, unknown>).warning as Record<string, unknown>),
        ...((featuresLabels.messages[locale.name].toast as Record<string, unknown>).warning as Record<string, unknown>),
      },
    };

    dateTimeFormats[locale.name] = { ...allCoreDateTimeFormats, ...allFeaturesDateTimeFormats } as DateTimeFormat;

    numberFormats[locale.name] = { ...allCoreNumberFormats, ...allFeaturesNumberFormats };
  }

  for (const localeModule of koruLocaleModules) {
    for (const locale of koruConfig.app.locale.locales) {
      labels[locale.name][localeModule.name] = localeModule.messages[locale.name][localeModule.name];

      const sections = ["buttons", "navigation", "roleModules"];
      for (const section of sections) {
        if (Object.prototype.hasOwnProperty.call(localeModule.messages[locale.name], section)) {
          labels[locale.name][section] = {
            ...(labels[locale.name][section] as Record<string, unknown>),
            ...(localeModule.messages[locale.name][section] as Record<string, unknown>),
          };
        }
      }

      if (Object.prototype.hasOwnProperty.call(localeModule.messages[locale.name], "toast")) {
        labels[locale.name].toast = {
          error: {
            ...((labels[locale.name].toast as Record<string, unknown>).error as Record<string, unknown>),
            ...((localeModule.messages[locale.name].toast as Record<string, unknown>).error as Record<string, unknown>),
          },
          success: {
            ...((labels[locale.name].toast as Record<string, unknown>).success as Record<string, unknown>),
            ...((localeModule.messages[locale.name].toast as Record<string, unknown>).success as Record<string, unknown>),
          },
          warning: {
            ...((labels[locale.name].toast as Record<string, unknown>).warning as Record<string, unknown>),
            ...((localeModule.messages[locale.name].toast as Record<string, unknown>).warning as Record<string, unknown>),
          },
        };
      }
    }
  }

  return createI18n({
    legacy: false,
    locale: koruConfig.app.locale.default,
    fallbackLocale: koruConfig.app.locale.fallback,
    messages: labels,
    datetimeFormats: dateTimeFormats,
    numberFormats: numberFormats,
  });
};

export const useKoruLocale = () => useI18n({ useScope: "global" });
