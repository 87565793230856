
import { ComputedRef, computed, defineComponent } from "vue";

import { LocaleInterface } from "@/core/modules/locale/interfaces/Locale.interface";
import { localeModel } from "@/core/modules/locale/models/Locale.model";

import { inputSelectAll } from "@/core/modules/helpers";

export default defineComponent({
  name: "KoruCurrencyField",
  props: {
    class: { type: String },
    currency: { type: String, default: "EUR" },
    model: { type: Number, default: 0 },
  },
  emits: ["update:model"],
  setup(props, { emit }) {
    const classesRef: ComputedRef<string> = computed(() => {
      const classes = "flex";
      return props.class != undefined ? `${classes} ${props.class}` : classes;
    });

    const locale: ComputedRef<LocaleInterface> = computed(() => localeModel.getSelectedLocale());

    const updateModel = (event: Record<string, unknown>) => {
      emit("update:model", event.value);
    };

    return {
      classesRef,
      locale,
      inputSelectAll,
      updateModel,
    };
  },
});
