import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { FirestoreDocument } from "../objects/FirestoreDocument";
import { User } from "@/core/modules/user/objects/User";

import { userCanReadAll, userCanReadOwned } from "@/core/modules/user/helpers";

export function addReadConditionToQuery<T extends FirestoreDocument>(
  query: firebase.firestore.Query<T>,
  roleModule: string
): firebase.firestore.Query<T> {
  // user can read all documents
  if (userCanReadAll(roleModule) === true) return query;

  const user: User = authStore.getter(authStoreTypes.getters.getUser);

  // user can read only documents created by him
  if (userCanReadOwned(roleModule) === true) return query.where("createdBy", "==", user.id);

  // user can read only documents with explicit read rights
  return query.where("rights.read", "array-contains", user.id);
}

export function getCollectionReference(collection: string): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
  return firebase.firestore().collection(collection);
}

export function getCollectionGroupReference(collection: string): firebase.firestore.Query<firebase.firestore.DocumentData> {
  return firebase.firestore().collectionGroup(collection);
}

export function getDocumentReference(path: string): firebase.firestore.DocumentReference {
  return firebase.firestore().doc(path);
}

export function runTransaction(performActions: (transaction: firebase.firestore.Transaction) => Promise<void>): void {
  try {
    firebase.firestore().runTransaction(async (transaction: firebase.firestore.Transaction) => {
      await performActions(transaction);
    });
  } catch (error: unknown) {
    console.error(error);
    throw new Error((error as Error).message);
  }
}
