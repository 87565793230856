import { ActionContext } from "vuex";

import { AuthStateInterface } from "@/core/modules/auth/interfaces/AuthState.interface";
import { authStoreTypes } from "../types";
import { koruAuth } from "@/core/modules/auth/module";
import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";

export const logout = async (context: ActionContext<AuthStateInterface, KoruStateInterface>): Promise<void> => {
  context.commit(authStoreTypes.mutations.loadingStart);

  try {
    await koruAuth.logout();

    context.commit(authStoreTypes.mutations.logoutSuccess);
  } catch (error: unknown) {
    context.commit(authStoreTypes.mutations.loadingStop);
    throw new Error((error as Error).message);
  }
};
