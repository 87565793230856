import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "image-list-action" }
const _hoisted_3 = { class: "text-center text-italic" }
const _hoisted_4 = {
  key: 1,
  class: "image-list-item"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "image-list-action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_FileUpload = _resolveComponent("FileUpload")!

  return (_ctx.fileRef === undefined)
    ? (_openBlock(), _createBlock(_component_FileUpload, {
        key: 0,
        accept: _ctx.accept !== undefined ? _ctx.accept : '.gif,.jpg,.jpeg,.png',
        fileLimit: 1,
        maxFileSize: _ctx.maxFileSize !== undefined ? _ctx.maxFileSize : _ctx.koruConfig.storage.maxFileSize,
        showUploadButton: false,
        onSelect: _ctx.onFileSelected
      }, {
        content: _withCtx(({ files, removeFileCallback }) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(files, (file, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "image-list-item",
              key: file.name + file.type + file.size
            }, [
              _createElementVNode("img", {
                src: _ctx.filePreview ?? _ctx.placeholderImg,
                alt: "file"
              }, null, 8, _hoisted_1),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_Button, {
                  icon: "pi pi-trash",
                  label: _ctx.t('buttons.delete'),
                  class: "p-button-warning",
                  onClick: ($event: any) => (removeFileCallback(index))
                }, null, 8, ["label", "onClick"])
              ])
            ]))
          }), 128))
        ]),
        empty: _withCtx(() => [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t("gen.filePlaceholder")), 1)
        ]),
        _: 1
      }, 8, ["accept", "maxFileSize", "onSelect"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createElementVNode("img", {
          src: _ctx.fileRef.url ?? _ctx.placeholderImg,
          alt: "file"
        }, null, 8, _hoisted_5),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.canDelete === true)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                icon: "pi pi-trash",
                label: _ctx.t('buttons.delete'),
                class: "p-button-warning",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteFile()))
              }, null, 8, ["label"]))
            : _createCommentVNode("", true)
        ])
      ]))
}