import firebase from "firebase/compat/app";
import "firebase/compat/storage";

export const getDownloadLink = async (path: string, name: string): Promise<string> => {
  try {
    const url: string = await firebase.storage().ref(path).child(name).getDownloadURL();
    return url;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
