import { registerLocaleModule } from "@/core/modules/locale/helpers";

import { branchLocaleModule } from "@/features/modules/branch/locales";
import { companyLocaleModule } from "@/features/modules/company/locales";
import { dashboardLocaleModule } from "@/features/modules/dashboard/locales";
import { examinationLocaleModule } from "@/features/modules/examination/locales";
import { referentLocaleModule } from "@/features/modules/referent/locales";

export const registerFeaturesLocales = () => {
  registerLocaleModule(branchLocaleModule);
  registerLocaleModule(companyLocaleModule);
  registerLocaleModule(dashboardLocaleModule);
  registerLocaleModule(examinationLocaleModule);
  registerLocaleModule(referentLocaleModule);
};
