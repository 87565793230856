
import { defineComponent } from "vue";

import { useKoruLocale } from "@/core/modules/locale";

export default defineComponent({
  name: "KoruContainer",
  props: {
    type: { type: String, default: "full" },
  },
  setup(props) {
    const { t } = useKoruLocale();

    const classes: Record<string, boolean> = {
      card: true,
      "w-full": true,
      "xl:w-8": props.type == "boxed",
      "flex-grow-1": props.type == "grow",
    };

    return {
      classes,
      t,
    };
  },
});
