export const en = {
  feedback: {
    feedback: "Feedback",
    message: "Message",
    messageError: "Insert a message",
    subject: "Subject",
    subjectError: "Insert a subject",
  },
  navigation: {
    feedback: "Feedback",
  },
  roleModules: {
    feedback: "Feedback",
  },
};
