import { koruConfig } from "@/core/modules/config";
import { LocaleInterface } from "@/core/modules/locale/interfaces/Locale.interface";

export const getLocales = (): LocaleInterface[] => {
  try {
    const locales: LocaleInterface[] = koruConfig.app.locale.locales;

    return locales;
  } catch (error: unknown) {
    console.error(error);
    throw new Error((error as Error).message);
  }
};
