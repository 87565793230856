import { registerRoutes } from "@/core/modules/router/helpers";

import { authRoutes } from "@/core/modules/auth/router";
import { feedbackRoutes } from "@/core/modules/feedback/router";
import { profileRoutes } from "@/core/modules/profile/router";
import { whoRoutes } from "@/core/modules/who/router";

export const registerCoreRoutes = () => {
  registerRoutes(authRoutes);
  registerRoutes(feedbackRoutes);
  registerRoutes(profileRoutes);
  registerRoutes(whoRoutes);
};
