import { ActionContext } from "vuex";

import { AuthStateInterface } from "@/core/modules/auth/interfaces/AuthState.interface";
import { authStoreTypes } from "../types";
import { koruAuth } from "@/core/modules/auth/module";
import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";

export const updateDocument = async (
  context: ActionContext<AuthStateInterface, KoruStateInterface>,
  newEmail: string,
  password: string
): Promise<void> => {
  context.commit(authStoreTypes.mutations.loadingStart);

  try {
    return koruAuth.updateEmail(newEmail, password);
  } catch (error: unknown) {
    console.error(error);
    throw new Error((error as Error).message);
  } finally {
    context.commit(authStoreTypes.mutations.loadingStop);
  }
};
