import { registerRoutes } from "@/core/modules/router/helpers";

import { companyRoutes } from "@/features/modules/company/router";
import { dashboardRoutes } from "@/features/modules/dashboard/router";
import { examinationRoutes } from "@/features/modules/examination/router";

export const registerFeaturesRoutes = () => {
  registerRoutes(companyRoutes);
  registerRoutes(dashboardRoutes);
  registerRoutes(examinationRoutes);
};
