import { differenceInCalendarDays } from "date-fns";

import { AddressField } from "@/core/fields";

export function formatAddress(address: AddressField): string {
  if (address === undefined) return "-";

  let formatted = "-";
  if (address.street !== undefined) formatted = `${address.street} -`;
  if (address.zipCode !== undefined) formatted += ` ${address.zipCode}`;
  if (address.town !== undefined) formatted += ` ${address.town}`;
  if (address.province !== undefined) formatted += ` (${address.province})`;
  if (address.country !== undefined) formatted += ` ${address.country}`;
  return formatted.trim();
}

export function formatExpiration(
  expiration: Date | undefined,
  d: (date: Date, format: string) => string,
  t: (entry: string) => string
): { text: string; class: string; tooltip: string } {
  if (expiration == undefined) {
    return {
      text: t("gen.expirationNotAvailable"),
      class: "badge status-blue",
      tooltip: "",
    };
  }
  const today: Date = new Date();
  today.setHours(0, 0, 0, 0);
  const daysLeft = differenceInCalendarDays(expiration, today);

  if (daysLeft >= 30) {
    const tooltip = `${daysLeft} ${t("gen.expirationDaysLeft")}`;
    return {
      text: d(expiration, "shortDate"),
      class: "badge status-green",
      tooltip: tooltip,
    };
  } else if (daysLeft >= 0) {
    const tooltip = `${daysLeft} ${t("gen.expirationDaysLeft")}`;
    return {
      text: d(expiration, "shortDate"),
      class: "badge status-orange",
      tooltip: tooltip,
    };
  }
  return {
    text: d(expiration, "shortDate"),
    class: "badge status-red",
    tooltip: t("gen.expirationExpired"),
  };
}

export function formatFileSize(bytes: number, si = true, dp = 2): string {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
}
