
import { computed, defineComponent, WritableComputedRef } from "vue";
import { google } from "google-maps";
import { Loader } from "@googlemaps/js-api-loader";

import { useKoruConfig } from "@/core/modules/config";
import { useKoruLocale } from "@/core/modules/locale";

import { uniqueId } from "@/core/plugins/unique-id";
import { AddressField } from "@/core/fields";

export default defineComponent({
  name: "KoruPlaces",
  emits: ["update:show", "update:address"],
  props: {
    show: { type: Boolean, default: false },
    address: { type: AddressField, default: new AddressField() },
  },
  setup(props, { emit }) {
    const { t } = useKoruLocale();
    const koruConfig = useKoruConfig();

    const addressRef: WritableComputedRef<AddressField> = computed({
      get: () => props.address as AddressField,
      set: (val) => emit("update:address", val),
    });

    const isVisible = computed(() => {
      return props.show;
    });

    const init = () => {
      const loader = new Loader({
        apiKey: koruConfig.api.googleMaps.key,
        version: "weekly",
        libraries: ["places"],
      });

      loader.load().then(() => {
        const addressField = document.querySelector(`#${elementId}`) as HTMLInputElement;
        const autocomplete = new window.google.maps.places.Autocomplete(addressField, {
          componentRestrictions: { country: ["it"] },
          fields: ["address_components"],
        });
        addressField.focus();
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          addressRef.value = new AddressField();
          let streetNumber = "";

          let street: string | undefined;
          let town: string | undefined;
          let zipCode: string | undefined;
          let province: string | undefined;
          let country: string | undefined;
          if (place.address_components !== undefined) {
            for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
              const componentType = component.types[0];
              switch (componentType) {
                case "street_number":
                  streetNumber = ", " + component.long_name;
                  break;
                case "route":
                  street = component.long_name + streetNumber;
                  break;
                case "administrative_area_level_3":
                  town = component.long_name;
                  break;
                case "postal_code":
                  zipCode = component.long_name;
                  break;
                case "administrative_area_level_2":
                  province = component.short_name;
                  break;
                case "country":
                  country = component.long_name;
                  break;
              }
            }
          }
          addressRef.value = new AddressField(street, town, zipCode, province, country);
          close();
        });
      });
    };

    const close = () => {
      emit("update:show", false);
    };

    const elementId = "hints-field-" + uniqueId();

    return {
      close,
      elementId,
      init,
      isVisible,
      t,
    };
  },
});
