import firebase from "firebase/compat/app";
import "firebase/compat/storage";

import { fetchAsBlob, convertBlobToBase64 } from "@/core/modules/storage/helpers";

export const getBase64 = async (path: string, name: string): Promise<string> => {
  try {
    const url: string = await firebase.storage().ref(path).child(name).getDownloadURL();
    const blob = await fetchAsBlob(url);
    const doubleBase64EncodedFile: string = (await convertBlobToBase64(blob)) as string;
    const doubleEncodedBase64String = doubleBase64EncodedFile.split(",")[1];
    return doubleEncodedBase64String;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
