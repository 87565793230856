import { LocaleModuleInterface } from "@/core/modules/locale/interfaces/LocaleModule.interface";

import { de } from "./de";
import { en } from "./en";
import { fr } from "./fr";
import { it } from "./it";

export const firestoreLocaleModule: LocaleModuleInterface = {
  name: "firestore",
  messages: { de: de, en: en, fr: fr, it: it },
};
