import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { LocaleInterface } from "@/core/modules/locale/interfaces/Locale.interface";
import { localeModel } from "../Locale.model";
import { User } from "@/core/modules/user/objects/User";

export const getSelectedLocale = (): LocaleInterface => {
  try {
    const user: User = authStore.getter(authStoreTypes.getters.getUser);

    const defaultLocale: LocaleInterface = localeModel.getLocales()[0];

    if (user === undefined) return defaultLocale;

    const selectedLocale: LocaleInterface | undefined = localeModel
      .getLocales()
      .find((locale: LocaleInterface) => locale.name === user.detail.locale);

    if (selectedLocale === undefined) return defaultLocale;

    return selectedLocale;
  } catch (error: unknown) {
    console.error(error);
    throw new Error((error as Error).message);
  }
};
