import { runValidatorFunction } from "@/core/modules/helpers";

export const isEmailUnique = async (email: string, userId = "empty"): Promise<boolean> => {
  try {
    if (email === undefined) return false;

    return runValidatorFunction("coreUserEmailValidator", email, userId);
  } catch (error: unknown) {
    console.error(error);
    throw new Error((error as Error).message);
  }
};
