import { FirestoreModel } from "@/core/modules/firestore/objects/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { User } from "../objects/User";
import { UserModelInterface } from "../interfaces/UserModel.interface";

import { isEmailUnique } from "./methods";

export class UserModel extends FirestoreModel<User> implements UserModelInterface {
  public constructor() {
    super(() => new User(), "users", LockPolicy.DiscardUnsyncedChanges, "user");
    this.beforeDeleteFunction = "coreUserBeforeDelete";
  }

  public async getDocuments(): Promise<User[]> {
    return super.getDocuments();
  }

  public async getDocument(userId: string): Promise<User> {
    return super.getDocument(userId);
  }

  public async createDocument(user: User): Promise<string> {
    user.setFullNames();
    return super.createDocument(user);
  }

  public async updateDocument(user: User): Promise<void> {
    user.setFullNames();
    return super.updateDocument(user);
  }

  public async deleteDocument(user: User): Promise<void> {
    return super.deleteDocument(user);
  }

  /* custom methods */

  public async isEmailUnique(email: string, userId?: string): Promise<boolean> {
    return isEmailUnique(email, userId);
  }
}

export const userModel: UserModel = new UserModel();
