import { ActionTree } from "vuex";

import { AuthStateInterface } from "../interfaces/AuthState.interface";
import { authStoreTypes } from "./types";
import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";

import { changePassword, forgotPassword, login, logout, recoverPassword, updateDocument } from "./actions/index";

export const actions: ActionTree<AuthStateInterface, KoruStateInterface> = {
  // authentication
  async [authStoreTypes.actions.login](context, { email, password }): Promise<void> {
    return login(context, email, password);
  },
  async [authStoreTypes.actions.logout](context): Promise<void> {
    return logout(context);
  },

  // password update
  async [authStoreTypes.actions.changePassword](context, { oldPassword, newPassword }): Promise<void> {
    return changePassword(context, oldPassword, newPassword);
  },
  async [authStoreTypes.actions.forgotPassword](context, email): Promise<void> {
    return forgotPassword(context, email);
  },
  async [authStoreTypes.actions.recoverPassword](context, { code, newPassword }): Promise<void> {
    return recoverPassword(context, code, newPassword);
  },

  // update document
  async [authStoreTypes.actions.changeEmail](context, { newEmail, password }): Promise<void> {
    return updateDocument(context, newEmail, password);
  },
};
