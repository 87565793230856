import { Ref, ref } from "vue";

import { KoruBrowserModuleInterface } from "../interfaces/KoruBrowserModule.interface";
import { RowCommandInterface } from "@/core/modules/layout/interfaces/RowCommand.interface";

export class KoruBrowserModule<T> implements KoruBrowserModuleInterface<T> {
  public canCreate = true;
  public canDelete = true;
  public canEdit = true;
  public canView = true;
  public createPath: string | undefined = undefined;
  public deleteDescriptionField = "";
  public documents: Ref<T[]> = ref([]);
  public explicitRequiredModule: string | undefined = undefined;
  public filters: Ref<Record<string, unknown>> = ref({});
  public loadingStop = true;
  public objectName = "";
  public path = "";
  public rowCommands: RowCommandInterface[] = [];
  public searchFields: string[] = [];
  public sortField = "";
  public sortOrder = 1;
  public suffix = "";

  public onDeleteAction(document: T): Promise<void> {
    console.log(document);
    return Promise.resolve();
  }

  public onLoadAction(): Promise<void> {
    return Promise.resolve();
  }

  public onLoadWithFiltersAction: (() => Promise<void>) | undefined = undefined;

  public onMountedAction(): Promise<void> {
    return Promise.resolve();
  }
}
