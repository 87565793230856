import { RouteRecordRaw } from "vue-router";

export const companyRoutes: Array<RouteRecordRaw> = [
  {
    path: "/company",
    name: "CompanyView",
    component: () => import("../views/CompanyView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "",
      requiredRight: "",
    },
  },
];
