import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

import { FirebaseConfigInterface } from "./interfaces/FirebaseConfig.interface";
import { koruConfig } from "@/core/modules/config";

export const firebaseInitialize = async (): Promise<void> => {
  const firebaseConfig: FirebaseConfigInterface = {
    apiKey: koruConfig.firebase.apiKey,
    appId: koruConfig.firebase.appId,
    authDomain: koruConfig.firebase.authDomain,
    clientEmail: koruConfig.firebase.clientEmail,
    databaseURL: koruConfig.firebase.databaseURL,
    messagingSenderId: koruConfig.firebase.messagingSenderId,
    projectId: koruConfig.firebase.projectId,
    region: koruConfig.firebase.region,
    storageBucket: koruConfig.firebase.storageBucket,
  };

  firebase.initializeApp(firebaseConfig);

  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
};
