export const dePrimevue = {
  startsWith: "Beginnt mit",
  contains: "Enthält",
  notContains: "Enthält nicht",
  endsWith: "Endet mit",
  equals: "Gleich",
  notEquals: "Ungleich",
  noFilter: "Kein Filter",
  lt: "Kleiner als",
  lte: "Kleiner als oder gleich",
  gt: "Größer als",
  gte: "Größer als oder gleich",
  dateIs: "Das Datum ist",
  dateIsNot: "Das Datum ist nicht",
  dateBefore: "Datum ist vor",
  dateAfter: "Datum ist nach",
  clear: "Löschen",
  apply: "Bewerben",
  matchAll: "Alle abgleichen",
  matchAny: "Übereinstimmung mit beliebigen",
  addRule: "Regel hinzufügen",
  removeRule: "Regel entfernen",
  accept: "Ja",
  reject: "Nein",
  choose: "Wählen",
  upload: "Hochladen",
  cancel: "Abbrechen",
  dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
  dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
  monthNamesShort: ["Jan", "Feb", "März", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
  today: "Heute",
  weekHeader: "Woche.",
  firstDayOfWeek: 1,
  dateFormat: "dd/mm/yy",
  weak: "Schwach",
  medium: "Mittel",
  strong: "Stark",
  passwordPrompt: "Geben Sie ein Passwort ein",
  emptyFilterMessage: "Keine Ergebnisse gefunden",
  emptyMessage: "Keine Optionen verfügbar",
};
