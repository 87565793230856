import firebase from "firebase/compat/app";
import "firebase/compat/storage";

export const fileExists = async (path: string, name: string): Promise<boolean> => {
  try {
    await firebase.storage().ref(path).child(name).getDownloadURL();
    return true;
  } catch (error: unknown) {
    return false;
  }
};
