import { ArrayField } from "../array-field/ArrayField";

export class NumberArrayField {
  public static fromFirestore(value: unknown): number[] {
    return ArrayField.fromFirestore(value, (item: unknown) => Number(item ?? 0));
  }

  public static toFirestore(value: number[]): unknown[] {
    return ArrayField.toFirestore(value, (item) => Number(item ?? 0));
  }

  public static fromOfflineCache(value: unknown): number[] {
    return ArrayField.fromOfflineCache(value, (item: unknown) => Number(item ?? 0));
  }

  public static toOfflineCache(value: number[]): unknown[] {
    return ArrayField.toOfflineCache(value, (item) => Number(item ?? 0));
  }
}
