import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-9 pb-0" }
const _hoisted_3 = ["href", "onClick"]
const _hoisted_4 = {
  key: 0,
  class: "p-menuitem-text"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "col-3 text-right pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Breadcrumb, {
          home: _ctx.home,
          model: _ctx.itemsRef,
          style: {"border":"0","padding":"0.75rem 0 0.75rem 0"}
        }, {
          item: _withCtx(({ item }) => [
            (item.to)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: item.to,
                  custom: ""
                }, {
                  default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                    _createElementVNode("a", {
                      href: href,
                      onClick: navigate,
                      class: _normalizeClass({ 'active-link': isActive, 'active-link-exact': isExactActive })
                    }, [
                      (item.label)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.label), 1))
                        : (item.icon)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 1,
                              class: _normalizeClass(["p-menuitem-text", item.icon])
                            }, null, 2))
                          : _createCommentVNode("", true)
                    ], 10, _hoisted_3)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item.label), 1))
          ]),
          _: 1
        }, 8, ["home", "model"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "buttons"),
        (_ctx.editRoute)
          ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
              key: 0,
              icon: "pi pi-pencil",
              class: "p-button-primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToEdit()))
            }, null, 512)), [
              [
                _directive_tooltip,
                _ctx.t('buttons.edit'),
                void 0,
                { bottom: true }
              ]
            ])
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}