
import { defineComponent } from "vue";

import { useKoruLocale } from "@/core/modules/locale";

export default defineComponent({
  name: "DevBar",
  setup() {
    const { t } = useKoruLocale();

    return {
      t,
    };
  },
});
