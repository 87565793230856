import firebase from "firebase/compat/app";
import "firebase/compat/storage";

export const deleteFile = async (path: string, name: string): Promise<void> => {
  try {
    return firebase.storage().ref(path).child(name).delete();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
